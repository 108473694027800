import { createAction, props } from '@ngrx/store';
import { ClasificationsProducts } from '../../models/catalogues/catalogues-clasification.model';
import { Products } from '../../models/catalogues/catalogues-products.model';

export const setCataloguesCategories = createAction(
    '[Catalogues] Set Catalogues categories',
    props<{ clasificationCategories: ClasificationsProducts[] }>()
);

export const getCataloguesCategories = createAction(
    '[Catalogues] Get Catalogues categories'
);
export const setProductsList = createAction(
    '[Catalogues] Set Products ',
    props<{ products: Products[] }>()
);

export const getProductsList = createAction('[Catalogues] Get Products ');

export const setSelectedCategory = createAction(
    '[Catalogues] Set Selected category',
    props<{ selectedclasification: ClasificationsProducts }>()
);

export const saveCategory = createAction(
    '[Catalogues] Save category',
    props<{ selectedclasification: ClasificationsProducts }>()
);


export const setSelectedProduct = createAction(
    '[Catalogues] Set Selected product',
    props<{ selectedProduct: Products }>()
);
export const updateProduct = createAction(
    '[Catalogues] Update Selected product',
    props<{ selectedProduct: Products }>()
);
export const createProduct = createAction(
    '[Catalogues] Create new product',
    props<{ selectedProduct: Products }>()
);
export const deleteProduct = createAction(
    '[Catalogues] Delete product',
    props<{ selectedProduct: Products }>()
);

export const deleteCatalogue = createAction(
    '[Catalogues] Delete catalogue',
    props<{ selectedclasification: ClasificationsProducts }>()
);

export const cleanProduct = createAction(
    '[Catalogues] Clean product selected',

);