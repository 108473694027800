import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'si-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
    @Input() message!: string;
    @Input() type!: AlertType;
    @HostBinding('class') alertClass: string;
    public alertIcon: AlertIconType;
    constructor() {}

    ngOnInit(): void {
        this.alertClass = this.type;
        switch (this.type) {
            case 'info':
                this.alertIcon = 'icon-warning';
                break;
            case 'success':
                this.alertIcon = 'icon-check';
                break;
            case 'error':
                this.alertIcon = 'icon-close';
                break;
            default:
                break;
        }
    }
}
