import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector,
} from '@ngrx/store';

import { DirectoryState } from '../states/directory.state';

export const selectDirectoryState: MemoizedSelector<object, DirectoryState> =
    createFeatureSelector<DirectoryState>('directory');

export const userSelected = createSelector(
    selectDirectoryState,
    (state: DirectoryState) => state.selectedUserDirectory
);

export const selectUsersDirectory = createSelector(
    selectDirectoryState,
    (state: DirectoryState) => state.usersDirectory
);

export const selectPositionsList = createSelector(
    selectDirectoryState,
    (state: DirectoryState) => state.positionsList
);
export const selectDepartmentsList = createSelector(
    selectDirectoryState,
    (state: DirectoryState) => state.departmentsList
);
