export interface NotificationsState {
    message: string;
    type: AlertType;
    component: string;
}

export const defaultNotificationsState: NotificationsState = {
    message: null,
    type: null,
    component: 'alert',
};
