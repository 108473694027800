import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClasificationsProducts } from '../../models/catalogues/catalogues-clasification.model';
import { selectClasificationCategories } from '../../store/selectors/catalogues.selectors';

@Pipe({
    name: 'clasifications',
})
export class ClasificationsPipe implements PipeTransform {
    private destroy$ = new Subject();
    clasification: ClasificationsProducts[];

    constructor(private store: Store) {
        this.store
            .select(selectClasificationCategories)
            .pipe(takeUntil(this.destroy$))
            .subscribe((v) => {
                if (v) {
                    this.clasification = v;
                }
            });
    }

    transform(value: unknown, ...args: unknown[]): unknown {
        let newValue = this.clasification.filter((c) => c.id === value || c.code === value);
        this.destroy$.next(true);
        return newValue[0] ? newValue[0].name : 'NF';
    }
}
