import { createAction, props } from '@ngrx/store';

import { ProductCategory } from '../../models/training/product-category.model';

export const setProductCategories = createAction(
    '[Training] Set Product categories',
    props<{ productCategories: ProductCategory[] }>()
);

export const getProductCategories = createAction(
    '[Training] Get Product categories'
);

export const setSelectedCourse = createAction(
    '[Training] Set Selected course',
    props<{ selectedCategory: ProductCategory }>()
);
export const updateTraining = createAction(
    '[Training] Update training',
    props<{ selectedCategory: ProductCategory }>()
);
export const deleteTraining = createAction(
    '[Training] Delete training',
    props<{ selectedCategory: ProductCategory }>()
);

export const clearSelectedCategory = createAction(
    '[Training] Clear Selected category'
);

// export const getCategoryCourses = createAction(
//     '[Training] Get Categories courses',
//     props<{ categoryId: string }>()
// );

// export const setCategoryCourses = createAction(
//     '[Training] Set Categories courses',
//     props<{ categoryCourses: CategoryCourse[] }>()
// );

export const createTraining = createAction(
    '[Training] Create Categories training',
    props<{ selectedCategory: ProductCategory }>()
);
