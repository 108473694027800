import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { UserLoggedInGuard } from 'src/app/core/guards/user-logged-in/user-logged-in.guard';
import { IRegistryPersonalInfo } from 'src/app/core/models/auth/personal_info.model';
import { roles } from 'src/app/core/models/auth/roles.model';
import { SieUser } from 'src/app/core/models/auth/user.model';
import { signOutUser } from 'src/app/core/store/actions/auth.actions';
import { navigate } from 'src/app/core/store/actions/navigation.actions';
import { showNotification } from 'src/app/core/store/actions/notifications.actions';
import {
    selectUserData,
    selectUserExtraInfo,
    selectUserRole,
} from 'src/app/core/store/selectors/auth.selectors';

@Component({
    selector: 'si-navmenu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit ,OnDestroy{
    public user_data: SieUser;
    public user_data_extra: Observable<IRegistryPersonalInfo>;
    public menuLinks: Array<NavMenuLink>;
    public Links: Array<NavMenuLink> = [
        {
            name: 'nav.Home',
            icon: 'icon-home',
            link: '/',
            authorize: false,
        },
        {
            name: 'nav.Admin',
            icon: 'icon-user',
            link: '/main/admin',
        },
        {
            name: 'nav.Catalogues',
            icon: 'icon-image',
            link: '/main/catalogues',
            authorize: false,
        },
        {
            name: 'nav.PriceList',
            icon: 'icon-download',
            link: '/main/price-list',
        },
        {
            name: 'nav.Distributos',
            icon: 'icon-location',
            link: '/main/distributors',
            authorize: true,
        },
        {
            name: 'nav.Blog',
            icon: 'icon-blog',
            link: '/main/blog',
            authorize: false,
        },
        {
            name: 'nav.Training',
            icon: 'icon-tools',
            link: '/main/training',
            authorize: false,
        },
        {
            name: 'nav.Directory',
            icon: 'icon-contacts',
            link: '/main/directory',
        },
        {
            name: 'nav.Projects',
            icon: 'icon-favorite',
            link: '/main/projects',
            authorize: false,
        },
        {
            name: 'nav.SearchProduct',
            icon: 'icon-search',
            link: '/main/search',
            authorize: true,
        },
        {
            name: 'nav.Selectors',
            icon: 'icon-monitor',
            link: '/main/selectors',
            authorize: true,
        },
        {
            name: 'nav.Demo',
            icon: 'icon-capacity',
            link: '/main/demo',
        },
        {
            name: 'nav.Configurators',
            icon: 'icon-settings',
            link: '/main/configurators',
        },
    ];
    public role$: Observable<roles>;
    private destroy$ = new Subject();

    @HostListener('class.movile--click') click() {
        alert();
    }
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store
            .select(selectUserData)
            .pipe(takeUntil(this.destroy$))
            .subscribe((u) => {
                if (u == null) {
                    this.menuLinks = this.Links.filter(
                        (c) => c.authorize != null
                    );
                } else {
                    this.user_data = u;
                }
            });

        this.user_data_extra = this.store.select(selectUserExtraInfo);

        this.store
            .select(selectUserRole)
            .pipe(takeUntil(this.destroy$))
            .subscribe((c) => {
                if (c != null) {
                    this.menuLinks = this.Links.filter((m) => {
                        if (c.sections.includes(m.link)) {
                            return m;
                        } else {
                            return null;
                        }
                    });
                } else {
                    this.menuLinks = this.Links.filter(
                        (c) => c.authorize != null
                    );
                }
            });
    }
    ngOnDestroy(): void {
        this.destroy$.next(true)
    }
    Open(url: string) {
        window.open(url, '_blank');
    }

    singOut() {
        this.store.dispatch(signOutUser());
    }
    goTo(url) {
        let nav = this.menuLinks.find((c) => c.link == url);
        if (this.user_data == undefined) {
            if (nav && nav.authorize == true) {
                this.store.dispatch(
                    showNotification({
                        message:
                            'Se requiere ingresar a su cuenta para acceder a este modulo',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
            } else {
                this.store.dispatch(navigate({ url: url.split('/') }));
            }
        } else {
            this.store.dispatch(navigate({ url: url.split('/') }));
        }
    }
}
