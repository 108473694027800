import { DistributorModel } from '../../models/distributors/distributor.model';

export interface DistributorsState {
    distributors: DistributorModel[];
    selected?: DistributorModel;
    loading: boolean;
}

export const defaultDistributorState: DistributorsState = {
    distributors: [],
    loading: false,
};
