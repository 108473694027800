import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CataloguesService } from '../../services/catalogues/catalogues.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

// import { cataloguesServiceice } from '../../services/training/training.service';
import {
    cleanProduct,
    createProduct,
    deleteCatalogue,
    deleteProduct,
    getCataloguesCategories,
    getProductsList,
    saveCategory,
    setCataloguesCategories,
    setProductsList,
    updateProduct,
} from '../actions/catalogues.actions';
import { navigate } from '../actions/navigation.actions';
import { showNotification } from '../actions/notifications.actions';
import { selectCataloguesState } from '../selectors/catalogues.selectors';

@Injectable()
export class CataloguesEffects {
    constructor(
        private actions$: Actions,
        private cataloguesService: CataloguesService,
        private store: Store,
        private localStorage: LocalStorageService
    ) {}
    public persistCataloguesState$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setProductsList),
                switchMap(() => this.store.select(selectCataloguesState)),
                map((state: any) =>
                    this.localStorage.setItem('catalogues', state)
                )
            );
        },
        {
            dispatch: false,
        }
    );

    public getClasificationsCategories$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCataloguesCategories),
            switchMap(() => this.cataloguesService.getClasificationsProducts()),
            map((clasificationCategories) =>
                setCataloguesCategories({ clasificationCategories })
            )
        );
    });


    public getProductsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProductsList),
            switchMap(() => this.cataloguesService.getProducts()),
            map((products) => setProductsList({ products }))
        );
    });
    public updateProductSelected$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProduct),
            switchMap(({ selectedProduct }) =>
                from(this.cataloguesService.updateProduct(selectedProduct))
            ),
            filter((u) => u == true),
            switchMap(() => [
                // cleanProduct(),

                getProductsList(),

                showNotification({
                    message: 'Información almacenada con exito ',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
       
    public saveCategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(saveCategory),
            switchMap(({ selectedclasification }) =>
                from(this.cataloguesService.createClasification(selectedclasification))
            ),
            filter((u) => u == true),
            switchMap(() => [
                getCataloguesCategories(),
                showNotification({
                    message: 'Información almacenada con exito ',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
       
    public deleteCatalogue$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteCatalogue),
            switchMap(({ selectedclasification }) =>
                from(this.cataloguesService.deleteClasification(selectedclasification))
            ),
            filter((u) => u == true),
            switchMap(() => [
                getCataloguesCategories(),
                showNotification({
                    message: 'Información almacenada con exito ',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    
    public createNewProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProduct),
            switchMap(({ selectedProduct }) =>
                from(this.cataloguesService.createProduct(selectedProduct))
            ),
            filter((u) => u == true),
            switchMap(() => [
                cleanProduct(),

                getProductsList(),
                showNotification({
                    message: 'Información almacenada con exito ',
                    alertType: 'success',
                    component: 'alert',
                }),
                navigate({ url: ['/main/admin/list'] }),
            ])
        );
    });
    public deleteProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteProduct),
            switchMap(({ selectedProduct }) =>
                from(this.cataloguesService.deleteProduct(selectedProduct))
            ),
            filter((u) => u == true),
            switchMap(() => [
                getProductsList(),
                cleanProduct(),
                showNotification({
                    message: 'Información almacenada con exito ',
                    alertType: 'success',
                    component: 'alert',
                }),
                navigate({ url: ['/main/admin/list'] }),
            ])
        );
    });

    // public getCategoryCourses$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(getCategoryCourses),
    //         switchMap(({ categoryId }) =>
    //             this.cataloguesService.getCategoryCourses(categoryId)
    //         ),
    //         map((categoryCourses) => setCategoryCourses({ categoryCourses }))
    //     );
    // });
}
