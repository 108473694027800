import { Action, createReducer, on } from '@ngrx/store';

import {
    clearSelectedCategory,
    createTraining,
    deleteTraining,
    getProductCategories,
    setProductCategories,
    setSelectedCourse,
    updateTraining,
} from '../actions/training.actions';
import { defaultTrainingState, TrainingState } from '../states/training.state';

const reducer = createReducer(
    defaultTrainingState,
    on(getProductCategories, (state: TrainingState) => ({
        ...state,
        loading: true,
    })),
    on(setProductCategories, (state: TrainingState, { productCategories }) => ({
        ...state,
        productCategories,
        loading: false,
    })),
    // on(getCategoryCourses, (state: TrainingState) => ({
    //     ...state,
    //     loading: true,
    // })),
    // on(setCategoryCourses, (state: TrainingState, { categoryCourses }) => ({
    //     ...state,
    //     categoryCourses,
    //     loading: false,
    // })),
    on(clearSelectedCategory, (state: TrainingState) => ({
        ...state,
        selectedCategory: null,
    })),
    on(setSelectedCourse, (state: TrainingState, { selectedCategory }) => ({
        ...state,
        selectedCategory,
    })),
    on(createTraining, (state: TrainingState, { selectedCategory }) => ({
        ...state,
        selectedCategory,
    })),
    on(updateTraining, (state: TrainingState, { selectedCategory }) => ({
        ...state,
        selectedCategory,
    })),
    on(deleteTraining, (state: TrainingState, { selectedCategory }) => ({
        ...state,
        selectedCategory,
    }))
);

export function trainingReducer(state: TrainingState, action: Action) {
    return reducer(state, action);
}
