import { IRegistryPersonalInfo } from '../../models/auth/personal_info.model';
import { roles } from '../../models/auth/roles.model';
import { SieUser } from '../../models/auth/user.model';

export interface AuthState {
    user_data?: SieUser;
    role_info: roles;
    registry: {
        personal_info?: IRegistryPersonalInfo;
    };
    loading: boolean;
}

export const defaultAuthState: AuthState = {
    registry: {},
    role_info: null,
    loading: false,
};
