import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { directoryUserModel } from '../../models/directory/directory-users.model';
import { throwAuthError } from '../../store/actions/auth.actions';
import { showNotification } from '../../store/actions/notifications.actions';

@Injectable({
    providedIn: 'root',
})
export class DirectoryService {
    constructor(private db: AngularFirestore, private store: Store) {}

    getDirectorySiemens(): Observable<directoryUserModel[]> {
        this.db.collection('directory').get().pipe;
        return this.db
            .collection('directory')
            .get()
            .pipe(
                map((res) =>
                    res.docs.map(
                        (c: any) =>
                            new directoryUserModel(
                                {
                                    name: c.data().name,
                                    email: c.data().email,
                                    phone: c.data().phone,
                                    responsability: c.data().responsability,
                                    location: c.data().location,
                                    department: c.data().department,
                                    position: c.data().position,
                                    status: c.data().status,
                                },
                                c.id
                            )
                    )
                )
            );
    }

    getPositionsSiemens(): Observable<DropdownOption[]> {
        return this.db
            .collection('positions')
            .get()
            .pipe(
                map((r: any) =>
                    r.docs.map((c: any) => ({
                        label: c.data().position,
                        value: c.id,
                    }))
                )
            );
    }
    getDeparmentsSiemens(): Observable<DropdownOption[]> {
        return this.db
            .collection('departments')
            .get()
            .pipe(
                map((r: any) =>
                    r.docs.map((c: any) => ({
                        label: c.data().deparment,
                        value: c.id,
                    }))
                )
            );
    }
    public async createDirectoryPeople(data = {}) {
        return this.db
            .collection('directory')
            .doc()
            .set({ ...data })
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
    public async updateUserDirectory(data: any) {
        return this.db
            .collection('directory')
            .doc(data.id)
            .set({ ...data })
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
    public async deleteUserDirectory(data: any) {
        return this.db
            .collection('directory')
            .doc(data.id)
            .delete()
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                return false;
            });
    }
}
