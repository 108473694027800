import { Action, createReducer, on } from '@ngrx/store';
import {
    createDistributor,
    deleteSelected,
    getDistributors,
    restoreDistributor,
    setDistributors,
    setSelected,
    updateSelected,
} from '../actions/distrubutors.actions';
import {
    defaultDistributorState,
    DistributorsState,
} from '../states/distributors.state';

// import { getDirectory } from '../actions/price-list.actions';

const reducer = createReducer(
    defaultDistributorState,
    on(setDistributors, (state: DistributorsState, { distributors }) => ({
        ...state,
        distributors,
        loading: false,
    })),
    on(getDistributors, (state: DistributorsState) => ({
        ...state,
        loading: true,
    })),
    on(setSelected, (state: DistributorsState, { selected }) => ({
        ...state,
        selected,
        loading: false,
    })),
    on(createDistributor, (state: DistributorsState, { selected }) => ({
        ...state,
        loading: false,
        selected,
    })),
    on(updateSelected, (state: DistributorsState, { selected }) => ({
        ...state,
        loading: false,
        selected,
    })),
    on(deleteSelected, (state: DistributorsState, { selected }) => ({
        ...state,
        loading: false,
        selected,
    })),
     on(restoreDistributor, (state: DistributorsState, { distributors }) => ({
        ...state,
        loading: false,
        distributors,
    }))
);

export function distributorsReducer(state: DistributorsState, action: Action) {
    return reducer(state, action);
}
