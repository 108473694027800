import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs/operators';
import { GalleryService } from '../../services/gallery/gallery.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import {deleteGallery, getGallery,saveGallery,setGallery} from '../actions/gallery.actions';
import { showNotification } from '../actions/notifications.actions';
import { selectGalleryState } from '../selectors/gallery.selectors';

@Injectable()
export class GalleryEffects {
    constructor(
        private actions$: Actions,
        private galleryServ: GalleryService ,       
        private localStorage: LocalStorageService,
        private store: Store,

    ) {}
    public persistDistributosState$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setGallery),
                switchMap(() => this.store.select(selectGalleryState)),
                map((state) => this.localStorage.setItem('gallery', state ))
            );
        },
        {
            dispatch: false,
        }
    );
    public getDirectoryUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getGallery),
            switchMap(() => this.galleryServ.getGallery()),
            map((gallery) => setGallery({ gallery }))
        );
    });
    public saveGallery$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(saveGallery),
            switchMap(({image}) => this.galleryServ.saveGallery(image)),
            filter((u) => u == true),
            switchMap(() => [
                // navigate({ url: ['main', 'distributors'] }),
                getGallery(),
                showNotification({
                    message: 'Información almacenada con exito!',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
  public deleteGallery$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteGallery),
            switchMap(({image}) => this.galleryServ.deleteGallery(image)),
            filter((u) => u == true),
            switchMap(() => [
                getGallery(),
                showNotification({
                    message: 'Operació exitosa!',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
}
