<router-outlet></router-outlet>
<ng-container *ngIf="showAlert$ | async as alert">
    <si-alert
        *ngIf="alert.message && alert.component == 'alert'"
        [type]="alert.type"
        [message]="alert.message"
    >
    </si-alert>
    <si-message
        *ngIf="alert.message && alert.component == 'message'"
        [type]="alert.type"
        [message]="alert.message"
    >
    </si-message>
</ng-container>

