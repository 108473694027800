<div  class="container">
    <!-- (change)=x"changeFile($event)" -->
    <div  style="width: 100%;">
        <p (click)="OpenChose()" >{{value ? value :"Ningun archivo seleccionado " }}</p>
        <!-- <input 
            type="text"
            [value]=""
            placeholder="Ningun archivo seleccionado "
        /> -->
        <input
            #fileChosse
            type="file"
            id="file"
            style="display: none"
            (change)="changeFile($event)"
        />
    </div>
</div>
<div class="progress" *ngIf="loading">
    <div class="color"></div>
</div>
