import { Action, createReducer, on } from '@ngrx/store';
import {
    cleanSelected,
    createProject,
    deleteProject,
    getProjects,
    requestProject,
    selectedProject,
    setProjects,
    updateProject,
} from '../actions/projects.actions';
import { defaultProjectsState, ProjectsState } from '../states/projects.state';

const reducer = createReducer(
    defaultProjectsState,
    on(getProjects, (state: ProjectsState) => ({
        ...state,
        loading: true,
    })),
    on(setProjects, (state: ProjectsState, { projectsList }) => ({
        ...state,
        projectsList,
        loading: false,
    })),
    on(createProject, (state: ProjectsState, { selectedProject }) => ({
        ...state,
        selectedProject,
        loading: true,
    })),
    on(updateProject, (state: ProjectsState, { selectedProject }) => ({
        ...state,
        selectedProject,
        loading: true,
    })),
    on(deleteProject, (state: ProjectsState, { selectedProject }) => ({
        ...state,
        selectedProject,
        loading: true,
    })),
    on(selectedProject, (state: ProjectsState, { selectedProject }) => ({
        ...state,
        selectedProject,
        loading: true,
    })),
    on(cleanSelected, (state: ProjectsState) => ({
        ...state,
        selectedProject: null,
        loading: true,
    })),
    on(requestProject, (state: ProjectsState, { request }) => ({
        ...state,
        request,
    }))
);

export function projectsReducer(state: ProjectsState, action: Action) {
    return reducer(state, action);
}
