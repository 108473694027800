import { IRegistryPersonalInfo } from '../../models/auth/personal_info.model';

export interface AdminState {
    users: IRegistryPersonalInfo[];
    userSelected: IRegistryPersonalInfo;
    loading: boolean;
}

export const defaultAdminState: AdminState = {
    users: [],
    userSelected: null,
    loading: false,
};
