<a
    target="_blank"
    href="https://new.siemens.com/global/es/general/aviso-de-privacidad-de-datos.html"
    >Aviso de privacidad
</a>
<div class="icons">
    <i class="icon-facebook" (click)="Open('https://www.facebook.com/groups/electricalProductsMX/')"  ></i>
    <i class="icon-instagram"(click)="Open('https://www.instagram.com/siemens/?hl=es-la')"  ></i>
    <i class="icon-linkedin" (click)="Open('https://www.linkedin.com/company/siemens')" ></i>   
</div>
