import {
    ProjectsModel,
    RequestModel,
} from '../../models/projects/projects.model';

export interface ProjectsState {
    projectsList: ProjectsModel[];
    selectedProject: ProjectsModel;
    request?: RequestModel;
    loading: boolean;
}

export const defaultProjectsState: ProjectsState = {
    projectsList: [],
    selectedProject: null,
    loading: false,
};
