import { GalleryModel } from "../../models/galery/galery.models";

export interface GalleryState {
    gallery: GalleryModel[];
    image: GalleryModel;
    loading: boolean;
}

export const defaultGalleryState: GalleryState = {
    gallery: [],
    image: null,
    loading: false,
};
