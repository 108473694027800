import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { from } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { navigate } from '../actions/navigation.actions';
import { cleanSelected } from '../actions/projects.actions';

@Injectable()
export class NavigationEFfects {
    constructor(private actions$: Actions, private router: Router) {}
    navigate$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(navigate),
                switchMap(({ url }) => from(this.router.navigate(url)))
            );
        },
        {
            dispatch: false,
        }
    );
}
