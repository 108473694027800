import { createAction, props } from '@ngrx/store';

export const navigate = createAction(
    '[Router] Navigate',
    props<{ url: string[] }>()
);

export const navigating = createAction(
    '[Router] Navigating',
    props<{ navigating: boolean }>()
);

export const setNavTitle = createAction(
    '[Router] Set nav title',
    props<{ title: string }>()
);

export const setNavPrevRoute = createAction(
    '[Router] Set prev route',
    props<{ url: string[] }>()
);



