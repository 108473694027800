import { createAction, props } from '@ngrx/store';

export const loadCountries = createAction('[Geolocation] Load Countries');

export const setCountries = createAction(
    '[Geolocation] Set Countries',
    props<{ countries: DropdownOption[] }>()
);

export const loadCountryStates = createAction(
    '[Geolocation] Load Country States',
    props<{ id: string }>()
);

export const setCountryStates = createAction(
    '[Geolocation] Set Country States',
    props<{ states: DropdownOption[] }>()
);
