import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GalleryModel } from '../../models/galery/galery.models';
import newsModel from '../../models/news/news.model';
import { showNotification } from '../../store/actions/notifications.actions';

@Injectable({
    providedIn: 'root',
})
export class GalleryService {
    
    constructor(private db: AngularFirestore, private store: Store) {}

    public getGallery(): Observable<GalleryModel[]> {
        return this.db
            .collection('gallery')
            .get()
            .pipe(
                map((c) =>
                    c.docs.map((doc) => ({
                        path: doc.data()['path'],
                        title: doc.data()['title'],
                        createAt : doc.data()['createAt'],
                        id: doc.id,
                    }))
                )
            );
    }
    


    saveGallery(path) {
        return this.db
            .collection('gallery')
            .doc()
            .set({...path})
            .then(() => true)
            .catch((err) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                return false;
            });
    }

    deleteGallery(image: GalleryModel): any {
        return this.db
        .collection('gallery')
        .doc(image.id)
        .delete()
        .then(() => true)
        .catch((err) => {
            this.store.dispatch(
                showNotification({
                    message: 'Error interno ',
                    alertType: 'error',
                    component: 'alert',
                })
            );
            return false;
        });
    }

}
