import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppReadyService } from './core/services/app-ready/app-ready.service';
import {  filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { selectNotificationsState } from './core/store/selectors/notifications.selectors';

import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './core/services/local-storage/local-storage.service';

@Component({
    selector: 'si-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public showAlert$: Observable<{
        message: string;
        type: AlertType;
        component: string;
    }>;
    message: any;
    constructor(
        private router: Router,
        private appReadyService: AppReadyService,
        private store: Store,
        private analytics: AngularFireAnalytics,
        private traslateServ : TranslateService,
        private storage :LocalStorageService,
    ) {}
    public ngOnInit() {
        this.traslateServ.setDefaultLang("es");
        this.traslateServ.use(this.storage.getItem('lang') || 'es')

        const navEndEvent = (this.showAlert$ = this.store.select(
            selectNotificationsState
        ));
        // Hide initial app loader
        this.router.events.pipe(filter((e) => e instanceof NavigationEnd));
        navEndEvent.subscribe((e: any) => {
            // --console.log(e.urlAfterRedirects);
            
            this.analytics.logEvent('page_path', e.urlAfterRedirects);
            this.appReadyService.trigger();
        });
    }
}
