import {  HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
//fireba
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAnalyticsModule, CONFIG } from '@angular/fire/compat/analytics';
import { AngularFireStorageModule } from '@angular/fire//compat/storage';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
// import { storeLogger } from 'ngrx-store-logger';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { firebaseDevConfig } from './core/config/firebase.config';
import { CustomSerializer } from './core/models/router-serializer/custom-router-serializer.model';
import { AppReadyService } from './core/services/app-ready/app-ready.service';
import { GeolocationEffects } from './core/store/effects/geoloc-lookups.effects';
import { NavigationEFfects } from './core/store/effects/navigation.effects';
import { NotificationsEffects } from './core/store/effects/notifications.effects';
import { TrainingEffects } from './core/store/effects/training.effects';
import { authReducer } from './core/store/reducers/auth.reducers';
import { geolocationReducer } from './core/store/reducers/geolocation.reducers';
import { initStateFromLocalStorage } from './core/store/reducers/local-storage.reducer';
import { navigationReducer } from './core/store/reducers/navigations.reducer';
import { notificationsReducer } from './core/store/reducers/notifications.reducers';
import { trainingReducer } from './core/store/reducers/training.reducers';
import { blogReducer } from './core/store/reducers/blog.reducer';
import { AppState } from './core/store/states/app.state';
import { SharedModule } from './shared/shared.module';
import { BlogEffects } from './core/store/effects/blog.effects';
import { cataloguesReducer } from './core/store/reducers/catalogues.reducers';
import { CataloguesEffects } from './core/store/effects/catalogues.effects';
import { directoryReducer } from './core/store/reducers/directory.reducers';
import { priceListReducer } from './core/store/reducers/price-list.reducers';
import { PriceListEFfects } from './core/store/effects/price-list.effects';
import { AuthEffects } from './core/store/effects/auth.effects';
import { DirectoryEffects } from './core/store/effects/directory.effects';
import { projectsReducer } from './core/store/reducers/projects.reducers';
import { ProjectsEffects } from './core/store/effects/projects.effects';
import { newsReducer } from './core/store/reducers/news.reducers';
import { NewsEffects } from './core/store/effects/news.effects';
import { AdminReducer } from './core/store/reducers/admin.reducers';
import { AdminEffects } from './core/store/effects/admin.effects';
import { MessagingService } from './core/services/messaging/messaging.service';
import { AsyncPipe, CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { distributorsReducer } from './core/store/reducers/distributors.reducers';
import { DistributorsEffects } from './core/store/effects/distributors.effects';
import { selectorsReduce } from './core/store/reducers/selectors.reducers';
import { SelectorsEffects } from './core/store/effects/selectors.effects';
import {  TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GalleryEffects } from './core/store/effects/gallery.effects';
import { galleryReducer } from './core/store/reducers/gallery.reducers';


export const rootReducers: ActionReducerMap<any> = {
    router: routerReducer,
};
const metaReducers: MetaReducer<AppState, any>[] = [
    // logger,
    initStateFromLocalStorage,
];

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}


@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(
            {
                auth: authReducer,
                router: routerReducer,
                navigation: navigationReducer,
                notifications: notificationsReducer,
                geolocation: geolocationReducer,
                training: trainingReducer,
                blog: blogReducer,
                distributors: distributorsReducer,
                catalogues: cataloguesReducer,
                directory: directoryReducer,
                price_list: priceListReducer,
                projects: projectsReducer,
                news: newsReducer,
                admin: AdminReducer,
                selectors: selectorsReduce,
                gallery : galleryReducer
            },
            { metaReducers }
        ),
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router',
            serializer: CustomSerializer,
        }),
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(firebaseDevConfig),
        AngularFireAnalyticsModule,
        EffectsModule.forRoot([
            AuthEffects,
            NavigationEFfects,
            NotificationsEffects,
            DistributorsEffects,
            GeolocationEffects,
            TrainingEffects,
            BlogEffects,
            CataloguesEffects,
            PriceListEFfects,
            DirectoryEffects,
            ProjectsEffects,
            NewsEffects,
            AdminEffects,
            SelectorsEffects,
            GalleryEffects
        ]),
        SharedModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        AppReadyService,
        MessagingService,
        AsyncPipe,
        HttpClient,
        {
            provide: CONFIG,
            useValue: {
                send_page_view: true,
                allow_ad_personalization_signals: true,
                anonymize_ip: true,
            },
        },{provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
