export const sortByStringValue = (array: any[], key: string, asc: boolean) => {
    const order = asc ? -1 : 1;
    return array.slice().sort((a: any, b: any) => {
        const x = a[key] ? a[key].toLowerCase() : a[key];
        const y = b[key] ? b[key].toLowerCase() : b[key];

        return x < y ? 1 * order : x > y ? -1 * order : 0;
    });
};

export const sortByNumberValue = (array: any[], key: string, asc: boolean) => {
    const order = asc ? -1 : 1;
    return array.slice().sort((a: any, b: any) => {
        const x = a[key];
        const y = b[key];

        return x < y ? 1 * order : x > y ? -1 * order : 0;
    });
};

export const sortByDateValue = (array: any[], key: string, asc: boolean) => {
    const order = asc ? -1 : 1;
    return array.slice().sort((a: any, b: any) => {
        const x = a[key];

        const y = b[key];

        return x < y ? 1 * order : x > y ? -1 * order : 0;
    });
};
