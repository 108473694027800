import { blogModel } from '../../models/blog/blog.model';

export interface BlogsState {
    Blogs?: blogModel[];
    selectedBlog: blogModel;
    loading: boolean;
    idBlog: string;
}

export const defaultBlogState: BlogsState = {
    Blogs: [],
    selectedBlog: null,
    loading: false,
    idBlog : ""
};
