import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'si-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchInputComponent),
            multi: true,
        },
    ],
})
export class SearchInputComponent
    implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
    @HostBinding('class.input') input = true;
    @Input() placeholder = 'Buscar...';
    @Input() formControl: UntypedFormControl;
    @Output() searchClicked = new EventEmitter();
    private destroy$ = new Subject();
    public term: string;
    public textInput = new UntypedFormControl();
    public onChange = (_: any) => {};
    public onTouched = () => {};
    public disabled = false;
    constructor() {}

    ngOnInit(): void {}
    ngAfterViewInit() {
        this.textInput.valueChanges
            .pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((v) => this.onChange(v));
    }
    ngOnDestroy() {
        this.destroy$.next(true);
    }
    writeValue(value: string) {
        this.term = value;
    }
    registerOnChange(func: any) {
        this.onChange = func;
    }
    registerOnTouched(func: any) {
        this.onTouched = func;
    }
    setDisabledState(state: boolean) {
        this.disabled = state;
    }
    onSearch() {
        this.onChange(this.textInput.value);
    }
}
