import { ClasificationsProducts } from '../../models/catalogues/catalogues-clasification.model';
import { Products } from '../../models/catalogues/catalogues-products.model';
export interface CataloguesState {
    clasificationCategories: ClasificationsProducts[];
    selectedclasification: ClasificationsProducts;
    selectedProduct: Products;
    products: Products[];
    loading: boolean;
}

export const defaultCataloguesState: CataloguesState = {
    clasificationCategories: [],
    selectedclasification: null,
    selectedProduct: null,
    products: [],
    loading: false,
};
