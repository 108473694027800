import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector,
} from '@ngrx/store';

import {GalleryState ,defaultGalleryState } from '../states/gallery.state';

export const selectGalleryState: MemoizedSelector<
    object,
    GalleryState
> = createFeatureSelector<GalleryState>('gallery');

export const selectGallery = createSelector(
    selectGalleryState,
    (state: GalleryState) => state.gallery
);
export const selectedPhoto = createSelector(
    selectGalleryState,
    (state: GalleryState) => state.image
);
