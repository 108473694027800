import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'si-tab-layout',
    templateUrl: './tab-layout.component.html',
    styleUrls: ['./tab-layout.component.scss'],
})
export class TabLayoutComponent implements OnInit {
    public tabs: Array<any> = [];
    constructor() {}

    ngOnInit(): void {}
}
