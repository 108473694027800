import { createAction, props } from '@ngrx/store';

export const showNotification = createAction(
    '[Notifications] Show notifications',
    props<{ message: string; alertType: AlertType; component: string }>()
);

export const hideNotification = createAction(
    '[Notifications] Hide notifications'
);
export const showMessageNotification = createAction(
    '[Notifications] Show message notifications',
    props<{ message: string; alertType: AlertType; component: string }>()
);
