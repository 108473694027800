export interface NavigationState {
    navigating: boolean;
    currentTitle: string;
    prevRoute?: string[];
}

export const defaultNavigationState: NavigationState = {
    navigating: false,
    currentTitle: null,
};
