import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LC1Model } from '../../models/selectors/cl1.selector';

@Injectable({
    providedIn: 'root',
})
export class PruebasService {
    constructor(private db: AngularFirestore) {}

    public lowercaseKeys = (obj) =>
        Object.keys(obj).reduce((acc, key) => {
            acc[key.toLowerCase()] = obj[key];
            return acc;
        }, {});

    public newSelector(data: any) {
        // console.log(data);
        return this.db
            .collection('selector_list')
            .doc()
            .set(data)
            .then((c) => true)
            .catch((err) => false);
    }
    public getSelectorsList(): Observable<any[]> {
        return this.db
            .collection('selector_list')
            .get()
            .pipe(map((res) => res.docs.map((doc) => doc.data())));
    }

    public async saveSpects(filteredOptions: any[]) {

        var del = this.db
        .collection('selector_spects').ref.where('value','==',item[0].value).get().then(c=>{
            c.docs.map(doc=>{
                doc.ref.delete();
            })
        })

        for await (var item of filteredOptions) {
            if (item.label) {
                this.db
                    .collection('selector_spects')
                    .doc()
                    .set(item)
                    .then((c) => c)
                    .catch((err) => err);
            }
        }
        return true;
    }

    public getSpectsSelector(selector: any): Observable<any[]> {
        return this.db
            .collection('selector_spects', (ref) =>
                ref.where('value', '==', selector)
            )
            .get()
            .pipe(
                map((res) =>
                    res.docs.map((doc) => {
                        let data = doc.data();
                        return data;
                    })
                )
            );
    }

    async restoreSelectors(data: any[] = []) {
        // console.log(data);

        var del = this.db.collection('selector_products').ref.where('selector','==',data[0].selector).get();
        (await del).docs.map(doc=> doc.ref.delete());

        data.forEach((item) => {
            return this.db
                .collection('selector_products')
                .doc()
                .set(item)
                .then((c) => {
                    return true;
                })
                .catch((err) => {
                    return false;
                });
        });
        return true;
    }

    public getProductsSelector(selector: any): Observable<any[]> {
        console.error(selector);

        return this.db
            .collection('selector_products', (ref) =>
                ref.where('selector', '==', selector)
            )
            .get()
            .pipe(
                map((res) =>
                    res.docs.map((doc) => {
                        let data = doc.data();
                        data['id'] = doc.id;
                        return data;
                    })
                )
            );
    }

    async deleteSelector(selector: string) {
        // console.log(selector);
        return new Promise(async (resolve, reject) => {
            await this.db
                .collection('selector_products', (ref) =>
                    ref.where('selector', '==', selector)
                )
                .get()
                .pipe(
                    map((doc) =>
                        doc.docs.forEach((c) => {
                            c.ref.delete();
                        })
                    )
                )
                .toPromise();
            await this.db
                .collection('selector_spects', (ref) =>
                    ref.where('value', '==', selector)
                )
                .get()
                .pipe(
                    map((doc) =>
                        doc.docs.forEach((c) => {
                            c.ref.delete();
                        })
                    )
                )
                .toPromise();

            this.db
                .collection('selector_list', (ref) =>
                    ref.where('value', '==', selector)
                )
                .get()
                .subscribe((c) =>
                    c.docs.forEach((doc) => {
                        return doc.ref
                            .delete()
                            .then((c) => resolve(true))
                            .catch((err) => resolve(false));
                    })
                );
        });
        // return new Promise((resolve, reject) => {
        //     this.db
        //         .collection('selector_list', (ref) =>
        //             ref.where('value', '==', selector)
        //         )
        //         .get()
        //         .pipe(
        //             map((doc) =>
        //                 doc.docs.forEach((c) => {
        //                     console.log(c.data());

        //                     c.ref.delete();
        //                     resolve(true);
        //                 })
        //             )
        //         );
        // });

        // this.db
        //     .collection('selector_products', (ref) =>
        //         ref.where('selector', '==', selector)
        //     )
        //     .get()
        //     .pipe(
        //         map((doc) =>
        //             doc.docs.forEach((c) => {
        //                 console.log(c.data());

        //                 c.ref.delete();
        //             })
        //         )
        //     );

        return true;
    }
}
