<span class="input">
    <input
        type="text"
        #input
        [placeholder]="placeholder"
        [ngModel]="value"
        autocomplete="false"
    />
    <i
        class="icon-arrow-down input__icon"
        (click)="onInputClick()"
        [ngClass]="{ opened: toggle }"
    ></i>
</span>
<div class="dropdown__options" *ngIf="toggle">
    <div
        *ngFor="let option of options"
        class="dropdown__option"
        (click)="onOptionSelected(option.value)"
    >
        <input type="checkbox" [id]="option.value" />
        <label [for]="option.value"></label> {{ option.label }}
    </div>
    <p
        *ngIf="options.length === 0"
        class="dropdown__option--no-items"
        disabled="true"
    >
        No hay coincidencias
    </p>
</div>
