import { Action, createReducer, on } from '@ngrx/store';
import {
    deleteUserRegister,
    getUsersRegister,
    setUserSelected,
    setUsersRegister,
    updateUserRegister,
} from '../actions/admin.actions';
import { AdminState, defaultAdminState } from '../states/admin.state';

const reducer = createReducer(
    defaultAdminState,
    on(getUsersRegister, (state: AdminState) => ({
        ...state,
        loading: true,
    })),
    on(setUsersRegister, (state: AdminState, { users }) => ({
        ...state,
        users: users,
        loading: false,
    })),
    on(setUserSelected, (state: AdminState, { user }) => ({
        ...state,
        userSelected: user,
        loading: false,
    })),
    on(updateUserRegister, (state: AdminState, { user }) => ({
        ...state,
        userSelected: user,
        loading: true,
    })),
    on(deleteUserRegister, (state: AdminState, { user }) => ({
        ...state,
        userSelected: user,
        loading: false,
    }))
);

export function AdminReducer(state: AdminState, action: Action) {
    return reducer(state, action);
}
