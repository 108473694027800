import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateService as Translate } from '../../services/translate/translate.service';

@Pipe({
  name: 'translatev2'
})
export class Translatev2Pipe implements PipeTransform {

  constructor(private translateServ : Translate ,  private translate : TranslateService){

  }
 async transform(value: string) : Promise<String> {
    if(this.translate.currentLang != 'es' && value != "NOM" ){
      if(value === "Medidores"){
        return "Measurements"
      }
      return  await this.translateServ.translate(value).then(c=> c ).catch(c=> c); 
    }else{
      return value;
    }
  }

}
