import { routerNavigatedAction, routerNavigationAction } from '@ngrx/router-store';
import { Action, createReducer, on } from '@ngrx/store';

import { navigating, setNavPrevRoute, setNavTitle } from '../actions/navigation.actions';
import { defaultNavigationState, NavigationState } from '../states/navigation.state';

const reducer = createReducer(
    defaultNavigationState,
    on(routerNavigationAction, (state) => ({ ...state, navigating: true })),
    on(routerNavigatedAction, (state) => ({ ...state, navigating: false })),
    on(setNavTitle, (state, { title }) => ({ ...state, currentTitle: title })),
    on(setNavPrevRoute, (state, { url }) => ({ ...state, prevRoute: url }))
);

export function navigationReducer(state: NavigationState, action: Action) {
    return reducer(state, action);
}
