import { createAction, props } from '@ngrx/store';
import { GalleryModel } from '../../models/galery/galery.models';

export const getGallery = createAction('[Gallery] Get Gallery');

export const setGallery = createAction(
    '[Gallery] Set Gallery',
    props<{ gallery: GalleryModel[] }>()
);


export const saveGallery = createAction(
    '[Gallery] Save Gallery',
    props<{ image: GalleryModel }>()
);

export const deleteGallery = createAction(
    '[Gallery] Delete Gallery',
    props<{ image: GalleryModel }>()
);