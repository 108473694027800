import { Pipe, PipeTransform } from '@angular/core';
import { sortByStringValue } from '../../functions/sorting.functions';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: Array<any>): unknown {
    return  sortByStringValue(array,'label',true);
  }

}
