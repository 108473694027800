import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limitText',
})
export class LimitTextPipe implements PipeTransform {
    transform(value: string, limit: number): unknown {
        let description = document.createElement('div');
        description.innerHTML = value;

        if (!value) {
            return `...`;
        }
        return `${description.textContent.substr(0, limit)} ...`;
    }
}
