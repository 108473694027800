export default class newsModel {
    id: string;
    author: Partial<newAuthor>;
    title: string;
    type: string;
    description: string;
    image: string;
    createAt: Date;
    constructor(data: any, id = '') {
        this.id = data.id || id;
        this.author = data.author;
        this.title = data.title;
        this.type = data.type;
        this.description = data.description;
        this.image = data.image;
        this.createAt = new Date(data.createAt.toDate());
    }
}
export interface newAuthor {
    id: string;
    name: string;
    email?: string;
    position?: string;
}
