export interface SelectorsState {
    selectorsList: any[];
    selectorSelected: string;
    filteredOptions: any[];
    filteredProducts: any[];
    productsList: any[];
    productSelected: any;
}

export const defaultSelectorsState: SelectorsState = {
    selectorsList: [],
    selectorSelected: null,
    filteredOptions: [],
    filteredProducts: [],
    productSelected: null,
    productsList: [],
};
