import { createAction, props } from '@ngrx/store';
import { IRegistryPersonalInfo } from '../../models/auth/personal_info.model';

export const getUsersRegister = createAction('[Admin] Get users register');

export const setUsersRegister = createAction(
    '[Admin] Set users register',
    props<{ users: IRegistryPersonalInfo[] }>()
);

export const setUserSelected = createAction(
    '[Admin] Set user selected',
    props<{ user: IRegistryPersonalInfo }>()
);
export const updateUserRegister = createAction(
    '[Admin] Update user data',
    props<{ user: IRegistryPersonalInfo }>()
);
export const deleteUserRegister = createAction(
    '[Admin] Delete user data',
    props<{ user: IRegistryPersonalInfo }>()
);
// export const setN = createAction(
//     '[Admin] Set user selected',
//     props<{ user: IRegistryPersonalInfo }>()
// );
// export const updateUserRegister = createAction(
//     '[Admin] Update user data',
//     props<{ user: IRegistryPersonalInfo }>()
// );
