import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { from, observable, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    PriceListDataModel,
    Price_list,
} from '../../models/price-list/price-list.model';
import { Store } from '@ngrx/store';
import { setPriceListData } from '../../store/actions/price-list.actions';
@Injectable({
    providedIn: 'root',
})
export class PriceListService {
    constructor(private db: AngularFirestore,private store : Store) {}

    async upload(data: any) {
        debugger;
        data.lastUpdate = new Date();
        let date = new Date().toISOString().slice(0, 10);

        let i = 1;

        let pricelistref = this.db
            .collection('price_list_data')
            .doc();

        let batch = this.db.firestore.batch();
        data.family.forEach((obj) => {
            const newDocRef = pricelistref.collection('family').doc().ref;
            batch.set(newDocRef, obj);

            i++;
            if (0 === i % 500) {
                batch.commit();
                batch = this.db.firestore.batch();
            }
        });

        data.products.forEach((obj) => {
            const newDocRef = pricelistref.collection('products').doc().ref;
            batch.set(newDocRef, obj);
            i++;
            if (0 === i % 500) {
                batch.commit();
                batch = this.db.firestore.batch();
            }
        });

        data.subfamily.forEach((obj) => {
            const newDocRef = pricelistref.collection('subfamily').doc().ref;
            batch.set(newDocRef, obj);
            i++;
            if (0 === i % 500) {
                batch.commit();
                batch = this.db.firestore.batch();
            }
        });

        data.table_headers.forEach((obj) => {
            const newDocRef = pricelistref
                .collection('table_headers')
                .doc().ref;
            batch.set(newDocRef, obj);
            i++;
            if (0 === i % 500) {
                batch.commit();
                batch = this.db.firestore.batch();
            }
        });

        data.product_manager.forEach((obj) => {
            const newDocRef = pricelistref
                .collection('product_manager')
                .doc().ref;
            batch.set(newDocRef, obj);
            i++;
            if (0 === i % 500) {
                batch.commit();
                batch = this.db.firestore.batch();
            }
        });

        data.table_info.forEach((obj) => {
            const newDocRef = pricelistref.collection('table_info').doc().ref;
            batch.set(newDocRef, obj);
            i++;
            if (0 === i % 500) {
                batch.commit();
                batch = this.db.firestore.batch();
            }
        });

        pricelistref.set({ lastUpdate: data.lastUpdate }, { merge: true });

        return await batch
            .commit()
            .then((c) => true)
            .catch((err) => err);
    }

     getPriceList() {
        const queryFn: QueryFn = (ref) =>
            ref.orderBy('lastUpdate', 'desc').limit(1);

        let registrosCollection = this.db.collection(
            'price_list_data',
            queryFn
        );
        return   registrosCollection.get().pipe(
            map((actions) => {
                return actions.docs.map( (a) => {
                    const ref = a.ref;
                    const promises = [
                        ref.collection('family').get(),
                        ref.collection('product_manager').get(),
                        ref.collection('products').get(),
                        ref.collection('subfamily').get(),
                        ref.collection('table_headers').get(),
                        ref.collection('table_info').get(),
                    ];

                    Promise.all(promises).then(res=> {
                        const info = new PriceListDataModel(
                            res[0].docs.map((c) => c.data()),
                            res[1].docs.map((c) => c.data()),
                            res[2].docs.map((c) => c.data()),
                            res[3].docs.map((c) => c.data()),
                            res[4].docs.map((c) => c.data()),
                            res[5].docs.map((c) => c.data()),
                        );
                        this.store.dispatch(setPriceListData({ price_list_data: info }))
                    });
                   
                    const data = a.data();
                    const id = a.id;
                    return { id, data  };
                });
            })
        );

        // return this.db
        //     .collection('price_list_data', (ref) =>
        //         ref.limit(1).orderBy('lastUpdate', 'desc')
        //     )
        //     .get()
        //     .pipe(
        //         map((c) =>
        //             c.docs.map((doc) => ({ data: doc.data(), id: doc.id }))
        //         )
        //     );
    }

    // async getPriceListData(id: string) {
    //     let doc = this.db.collection('price_list_data').doc(id);

    //     // .pipe(map((c) => c.docs.map(async (doc) => {

    //     let family = await doc.ref
    //         .collection('family')
    //         .get()
    //         .then((c) => c.docs.map((c) => c.data()));

    //     let products = await doc.ref
    //         .collection('products')
    //         .get()
    //         .then((c) => c.docs.map((c) => c.data()));

    //     let subfamily = await doc.ref
    //         .collection('subfamily')
    //         .get()
    //         .then((c) => c.docs.map((c) => c.data()));

    //     let table_headers = await doc.ref
    //         .collection('table_headers')
    //         .get()
    //         .then((c) => c.docs.map((c) => c.data()));

    //     let product_manager = await doc.ref
    //         .collection('product_manager')
    //         .get()
    //         .then((c) => c.docs.map((c) => c.data()));

    //     let table_info = await doc.ref
    //         .collection('table_info')
    //         .get()
    //         .then((c) => c.docs.map((c) => c.data()));

    //     let data = new PriceListDataModel(
    //         family,
    //         product_manager,
    //         products,
    //         subfamily,
    //         table_headers,
    //         table_info
    //     );

    //     return data;
    //     debugger;
    // }

    async updatePrice_List(urlFile) {
        let id = await this.db
            .collection('price_list_data', (ref) =>
                ref.limit(1).orderBy('lastUpdate', 'desc')
            )
            .get()
            .toPromise()
            .then((c) => c.docs[0].id);

        return this.db
            .collection('price_list_data')
            .doc(id)
            .set({ fileUrl: urlFile }, { merge: true })
            .then((c) => true);
    }
    // getProducts(): Observable<any> {
    //     const priceList$ = this.db
    //         .collection('price_list')
    //         .get()
    //         .pipe(
    //             map((c) =>
    //                 c.docs.map((doc) => {
    //                     let data = doc.data();
    //                     data['id'] = doc.id;
    //                     return data;
    //                 })
    //             )
    //         );

    //     return priceList$;
    // }
    // getFamilies(): Observable<any> {
    //     const priceList$ = this.db
    //         .collection('price_list_Families')
    //         .get()
    //         .pipe(
    //             map((c) =>
    //                 c.docs.map((doc) => {
    //                     let data = doc.data();
    //                     data['id'] = doc.id;
    //                     return data;
    //                 })
    //             )
    //         );

    //     return priceList$;
    // }
    // getSubFamilies(): Observable<any> {
    //     const priceList$ = this.db
    //         .collection('price_list_subFamilies')
    //         .get()
    //         .pipe(
    //             map((c) =>
    //                 c.docs.map((doc) => {
    //                     let data = doc.data();
    //                     data['id'] = doc.id;
    //                     return data;
    //                 })
    //             )
    //         );

    //     return priceList$;
    // }
    // getTableHeaders(): Observable<any> {
    //     const priceList$ = this.db
    //         .collection('price_list_Headers', (ref) =>
    //             ref.limit(1).orderBy('lastedUpdate', 'desc')
    //         )
    //         .get()
    //         .pipe(
    //             map((c) =>
    //                 c.docs.map((doc) => {
    //                     let data = doc.data();
    //                     data['id'] = doc.id;
    //                     return data;
    //                 })
    //             )
    //         );

    //     return priceList$;
    // }
    // getTableInfo(): Observable<any> {
    //     const priceList$ = this.db
    //         .collection('price_list_info', (ref) =>
    //             ref.limit(1).orderBy('lastedUpdate', 'desc')
    //         )
    //         .get()
    //         .pipe(
    //             map((c) =>
    //                 c.docs.map((doc) => {
    //                     let data = doc.data();
    //                     data['id'] = doc.id;
    //                     return data;
    //                 })
    //             )
    //         );

    //     return priceList$;
    // }
    // getManagers(): Observable<any> {
    //     const priceList$ = this.db
    //         .collection('price_list_managers')
    //         .get()
    //         .pipe(
    //             map((c) =>
    //                 c.docs.map((doc) => {
    //                     let data = doc.data();
    //                     data['id'] = doc.id;
    //                     return data;
    //                 })
    //             )
    //         );

    //     return priceList$;

    // }
}
