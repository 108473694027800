import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { PriceListService } from '../../services/price-list/price-list.service';
import { navigate } from '../actions/navigation.actions';
import { showNotification } from '../actions/notifications.actions';
import {
    getPriceList,
    getPriceListData,
    setPriceList,
    setPriceListData,
    updatePriceList,
} from '../actions/price-list.actions';
import { selectPriceListState } from '../selectors/price-list.selectors';
// import { getDirectory, setDirectory } from '../actions/directory.actions';

@Injectable()
export class PriceListEFfects {
    constructor(
        private actions$: Actions,
        private priceListService: PriceListService,
        private store : Store,
        private localStorage: LocalStorageService

    ) {}

    // public persistCataloguesState$ = createEffect(
    //     () => {
    //         return this.actions$.pipe(
    //             ofType(setPriceList),
    //             switchMap(() => this.store.select(selectPriceListState)),
    //             map((state: any) =>
    //                 this.localStorage.setItem('PriceList', state)
    //             )
    //         );
    //     },
    //     {
    //         dispatch: false,
    //     }
    // );


    getPriceList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getPriceList),
            switchMap(() => from(this.priceListService.getPriceList())),
            map((price_list : any) => setPriceList({ price_list }))
        );
    });
    
    // getPriceListData$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(getPriceListData),
    //         switchMap(({id}) => from(this.priceListService.getPriceListData(id))),
    //         map((price_list) =>
    //             setPriceListData({ price_list_data: price_list })
    //         )
    //     );
    // });

    updatePriceList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updatePriceList),
            switchMap(({ price_list }) =>
                from(this.priceListService.updatePrice_List(price_list))
            ),
            filter((u) => u != null),
            switchMap(() => [
                navigate({ url: ['main', 'price-list'] }),
                showNotification({
                    message: 'Lista actualizada con exito ',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
}
