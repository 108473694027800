import { createAction, props } from '@ngrx/store';
import {
    ProjectsModel,
    RequestModel,
} from '../../models/projects/projects.model';

export const setProjects = createAction(
    '[Projects] Set projects list',
    props<{ projectsList: ProjectsModel[] }>()
);

export const getProjects = createAction('[Projects] Get projects list');

export const createProject = createAction(
    '[Projects] Create new project',
    props<{ selectedProject: ProjectsModel }>()
);
export const updateProject = createAction(
    '[Projects] Update project',
    props<{ selectedProject: ProjectsModel }>()
);
export const deleteProject = createAction(
    '[Projects] Delete project',
    props<{ selectedProject: ProjectsModel }>()
);
export const cleanSelected = createAction('[Projects] Clean selected ');

export const selectedProject = createAction(
    '[Projects] Selected project',
    props<{ selectedProject: ProjectsModel }>()
);
export const requestProject = createAction(
    '[Projects] Request project',
    props<{ request: RequestModel }>()
);
