export class ProjectsModel {
    public id?: string;
    public title?: string;
    public contain?: string;
    public author?: Author;
    public images?: string[];
    public createAt?: Date;

    constructor(item: any, id = '') {
        this.id = id || item.id;
        this.title = item.title;
        this.contain = item.contain;
        this.author = new Author(item.author);
        this.images = item.images;
        this.createAt = item.createAt;
    }
}

export class Author {
    id?: string;
    name?: string;
    email?: string;
    position?: string;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.email = data.email;
        this.position = data.position;
    }
}

export class RequestModel {
    id: string;
    type: string;
    text: string;
    images: string;
    date: string;
    status: string;
    constructor(item: any) {
        this.id = item.id;
        this.type = item.type;
        this.text = item.text;
        this.images = item.images;
        this.date = item.date;
        this.status = item.status;
    }
}
