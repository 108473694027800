import { Action, createReducer, on } from '@ngrx/store';

// import { getDirectory } from '../actions/price-list.actions';

import {
    defaultDirectoryState,
    DirectoryState,
} from '../states/directory.state';
import {
    createNewUserDirectory,
    deleteUserDirectory,
    getDirectory,
    removeSelectedDirecory,
    setDirectory,
    setUserSelectedDirectory,
    updateUserDirectory,
} from '../actions/directory.actions';

const reducer = createReducer(
    defaultDirectoryState,
    on(getDirectory, (state: DirectoryState) => ({
        ...state,
        loading: true,
    })),
    on(setDirectory, (state: DirectoryState, { usersDirectory }) => ({
        ...state,
        usersDirectory,
        loading: true,
    })),
    // on(getDepartments, (state: DirectoryState) => ({
    //     ...state,
    //     loading: true,
    // })),
    // on(setDepartments, (state: DirectoryState, { departmentsList }) => ({
    //     ...state,
    //     departmentsList,
    //     loading: true,
    // })),
    // on(getPositions, (state: DirectoryState) => ({
    //     ...state,
    //     loading: true,
    // })),
    // on(setPositions, (state: DirectoryState, { positionsList }) => ({
    //     ...state,
    //     positionsList,
    //     loading: true,
    // })),
    on(
        setUserSelectedDirectory,
        (state: DirectoryState, { selectedUserDirectory }) => ({
            ...state,
            selectedUserDirectory,
        })
    ),
    on(removeSelectedDirecory, (state: DirectoryState) => ({
        ...state,
        selectedUserDirectory: null,
    })),
    on(
        createNewUserDirectory,
        (state: DirectoryState, { selectedUserDirectory }) => ({
            ...state,
            selectedUserDirectory,
            loading: true,
        })
    ),
    on(
        updateUserDirectory,
        (state: DirectoryState, { selectedUserDirectory }) => ({
            ...state,
            selectedUserDirectory,
            loading: true,
        })
    ),
    on(
        deleteUserDirectory,
        (state: DirectoryState, { selectedUserDirectory }) => ({
            ...state,
            selectedUserDirectory,
            loading: true,
        })
    )
);

export function directoryReducer(state: DirectoryState, action: Action) {
    return reducer(state, action);
}
