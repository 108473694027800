import {
    CategoryCourse,
    ProductCategory,
} from '../../models/training/product-category.model';

export interface TrainingState {
    productCategories: ProductCategory[];
    categoryCourses: CategoryCourse[];
    selectedCategory: ProductCategory;
    loading: boolean;
}

export const defaultTrainingState: TrainingState = {
    productCategories: [],
    categoryCourses: [],
    selectedCategory: null,
    loading: false,
};
