import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IRegistryPersonalInfo } from '../../models/auth/personal_info.model';
import { AdminService } from '../../services/admin/admin.service';
import {
    deleteUserRegister,
    getUsersRegister,
    setUsersRegister,
    updateUserRegister,
} from '../actions/admin.actions';
import { showNotification } from '../actions/notifications.actions';

@Injectable()
export class AdminEffects {
    constructor(private actions$: Actions, private adminServ: AdminService) {}

    public getBlogs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUsersRegister),
            switchMap(() => this.adminServ.getUsersRegister()),
            map((users: IRegistryPersonalInfo[]) => setUsersRegister({ users }))
        );
    });
    public updateUserRegister$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateUserRegister),
            switchMap(({ user }) => from(this.adminServ.updateUserInfo(user))),
            filter((c) => c === true),
            switchMap(() => [
                getUsersRegister(),
                showNotification({
                    message: 'Usuario creado con éxito',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    public deleteUserRegister$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserRegister),
            switchMap(({ user }) => from(this.adminServ.deleteUserInfo(user))),
            filter((c) => c === true),
            switchMap(() => [
                getUsersRegister(),
                showNotification({
                    message: 'Usuario eliminado con exito',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
}
