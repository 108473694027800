<section class="buttons" *ngIf="controls">
    <i class="btn btn--transparent icon-arrow-left" (click)="onPrevClick()"></i>
    <i class="btn icon-arrow-right" (click)="onNextClick()"></i>
</section>
<section class="images" [ngStyle]="{ 'margin-left': slideMargin }">
    <div
        *ngFor="let image of images"
        [ngStyle]="{ 'background-image': 'url(' + image + ')' }"
    ></div>
    <div *ngIf="Modelo3D" >
        <iframe
            [src]="Modelo3D | safe:'url'"
        >
        </iframe>
    </div>
</section>
