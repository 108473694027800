import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProductCategory } from '../../models/training/product-category.model';
import { throwAuthError } from '../../store/actions/auth.actions';

@Injectable({
    providedIn: 'root',
})
export class TrainingService {
    constructor(private db: AngularFirestore, private store: Store) {}

    public getProductCategories(): Observable<ProductCategory[]> {
        return this.db
            .collection('training')
            .get()
            .pipe(
                map((res) =>
                    res.docs.map(
                        (doc) => new ProductCategory(doc.data(), doc.id)
                    )
                )
            );
    }

    public async createProductCategory(data: ProductCategory) {
        let newTraining = this.db.collection('training').doc();

        return newTraining
            .set(data)
            .then(() => true)
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }

    public async updateTraining(data: ProductCategory) {
        let editData = this.db.collection('training').doc(data.id);

        return editData
            .set(data)
            .then(() => true)
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }

    public async deleteTraining(data: ProductCategory) {
        let editData = this.db.collection('training').doc(data.id);

        return editData
            .delete()
            .then(() => true)
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
}
