import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectsModel } from '../../models/projects/projects.model';
import { throwAuthError } from '../../store/actions/auth.actions';
import { showNotification } from '../../store/actions/notifications.actions';

@Injectable({
    providedIn: 'root',
})
export class ProjectsService {
    constructor(private db: AngularFirestore, private store: Store) {}

    getProjectsList(): Observable<ProjectsModel[]> {
        return this.db
            .collection('projects')
            .get()
            .pipe(
                map((res) =>
                    res.docs.map((doc) => new ProjectsModel(doc.data(), doc.id))
                )
            );
    }

    async createProject(project = {}) {
        let newData = JSON.parse(JSON.stringify(project));
        return this.db
            .collection('projects')
            .doc()
            .set(newData)
            .then((c) => {
                return true;
            })
            .catch((err) => {
                this.store.dispatch(throwAuthError({ code: '404' }));

                return false;
            });
    }

    async sendRequest(data: any) {
        let newData = JSON.parse(JSON.stringify(data));

        return await this.db
            .collection('request_projects')
            .doc()
            .set(newData)
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }

    public async updateProject(data: any) {
        let newData = JSON.parse(JSON.stringify(data));

        return await this.db
            .collection('projects')
            .doc(data.id)
            .set({ ...newData })
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
    public async deleteProject(data: any) {
        return await this.db
            .collection('projects')
            .doc(data.id)
            .delete()
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
}
