import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorCodesService {
    constructor() {}
    public mapErrorCode(code: string) {
        switch (code) {
            case 'auth/invalid-email':
            case 'auth/wrong-password':
                return 'Las credenciales ingresadas son incorrectas';
            case 'auth/user-disabled':
                return 'Tu cuenta se encuantra deshabilitada. Contacta a un administrador';
            case 'auth/user-not-found':
                return 'El usuario ingresado no está registrado';
            case 'auth/email-already-in-use':
                return 'El usuario ya está registrado para otra cuenta';
            case 'auth/weak-password':
                return 'Tu contraseña no es segura. Ingresa una que contenga dígitos y/o símbolos';
            default:
                return 'Oop! Hemos tenido un error. Intentalo de nuevo';
        }
    }
}
