import newsModel from '../../models/news/news.model';

export interface NewsState {
    loading: boolean;
    newsList: newsModel[];
    newSelected?: newsModel;
}
export const defaultNewState: NewsState = {
    loading: false,
    newsList: [],
};
