import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector,
} from '@ngrx/store';
import { CataloguesState } from '../states/catalogues.state';

// import { TrainingState } from '../states/training.state';

export const selectCataloguesState: MemoizedSelector<object, CataloguesState> =
    createFeatureSelector<CataloguesState>('catalogues');

export const selectClasificationCategories = createSelector(
    selectCataloguesState,
    (state: CataloguesState) => state.clasificationCategories
);

export const selectedCategory = createSelector(
    selectCataloguesState,
    (state: CataloguesState) => state.selectedclasification
);

export const productsList = createSelector(
    selectCataloguesState,
    (state: CataloguesState) => state.products
);

export const selectedProduct = createSelector(
    selectCataloguesState,
    (state: CataloguesState) => state.selectedProduct
);

export const dropdownNormative = createSelector(
    selectCataloguesState,
    (state: CataloguesState) =>
        state.clasificationCategories
            .filter((c) => c.type == 'norm')
            .map((c) => {
                return { label: c.name, value: c.id };
            })
);
export const dropdownType = createSelector(
    selectCataloguesState,
    (state: CataloguesState) =>
        state.clasificationCategories
            .filter((c) => c.type == 'type')
            .map((c) => {
                return { label: c.name, value: c.id };
            })
);

export const dropdownFamilia = createSelector(
    selectCataloguesState,
    (state: CataloguesState) =>
        state.clasificationCategories
            .filter((c) => c.type == 'fam')
            .map((c) => {
                return { label: c.name, value: c.id };
            })
);
export const dropdownArea = createSelector(
    selectCataloguesState,
    (state: CataloguesState) =>
        state.clasificationCategories
            .filter((c) => c.type == 'area')
            .map((c) => {
                return { label: c.name, value: c.id };
            })
);

export const selectNormative = createSelector(
    selectCataloguesState,
    (state: CataloguesState) =>
        state.clasificationCategories.filter((c) => c.type == 'norm')
);
export const selectType = createSelector(
    selectCataloguesState,
    (state: CataloguesState) =>
        state.clasificationCategories.filter((c) => c.type == 'type')
);

export const selectFamilia = createSelector(
    selectCataloguesState,
    (state: CataloguesState) =>
        state.clasificationCategories.filter((c) => c.type == 'fam')
);
export const selectArea = createSelector(
    selectCataloguesState,
    (state: CataloguesState) =>
        state.clasificationCategories.filter((c) => c.type == 'area')
);
