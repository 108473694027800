import { createAction, props } from '@ngrx/store';
import { PriceListDataModel, Price_list } from '../../models/price-list/price-list.model';

export const setPriceList = createAction(
    '[Price_List] Set Price List',
    props<{ price_list: Price_list }>()
);
export const updatePriceList = createAction(
    '[Price_List] Update Price List ',
    props<{ price_list: Price_list }>()
);

export const getPriceListData = createAction(
    '[Price_List] get Price List Data',
    props<{ id: any }>()
);

export const setPriceListData = createAction(
    '[Price_List] Set Price List Data',
    props<{ price_list_data: PriceListDataModel }>()
);

export const setFamilieSelected = createAction(
    '[Price_List] Set familie selected',
    props<{ familieSelected: any }>()
);
export const setsubFamilieSelected = createAction(
    '[Price_List] Set familie selected',
    props<{ subFamilieSelected: any }>()
);

export const setProductSelected = createAction(
    '[Price_List] Set Product selected',
    props<{ productoSelected: any }>()
);
export const findProduct = createAction(
    '[Price_List] Find Product',
    props<{ searchValue: any }>()
);

export const getPriceList = createAction('[Price_List] Get Price List ');
