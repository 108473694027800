import { PriceListDataModel, Price_list } from '../../models/price-list/price-list.model';

export interface price_listState {
    price_list: Price_list;
    loading: boolean;
    price_list_data:PriceListDataModel
    familieSelected: any;
    subFamilieSelected: any;
    productoSelected: any;
    searchValue : string
}

export const defaultPriceListState: price_listState = {
    price_list: null,
    loading: false,
    price_list_data: {
        family: [],
        product_manager: [],
        products: [],
        subfamily: [],
        table_headers: [],
        table_info: [],
    },
    familieSelected: null,
    subFamilieSelected: null,
    productoSelected: null,
    searchValue : "",
};
