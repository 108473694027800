import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { delay, filter, first, map, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import { signOutUser } from 'src/app/core/store/actions/auth.actions';
import { navigate } from 'src/app/core/store/actions/navigation.actions';
import { selectAuthState } from 'src/app/core/store/selectors/auth.selectors';
import {
    selectNavPrevRoute,
    selectNavState,
    selectNavTitle,
} from 'src/app/core/store/selectors/navigation.selectors';

@Component({
    selector: 'si-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
    public title$: Observable<string>;
    public prevRoute$: Observable<string[]>;
    public menuOpen = false;
    public notifications: any[] = [];
    @Output() menuToggled = new EventEmitter();
    private destroy$ = new Subject();
    public hiddeControls: boolean = false;
    public lang: string;
    public iconLang: string;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.menuOpen && !this.eRef.nativeElement.contains(event.target)) {
            this.menuOpen = false;
            this.menuToggled.emit(this.menuOpen);
        }
    }

    constructor(
        public store: Store,
        private eRef: ElementRef,
        private authServ: AuthService,
        private traslateServ : TranslateService,
        private storage :LocalStorageService,
    ) {}

    ngOnInit(): void {
       this.lang =    this.storage.getItem('lang') || 'es';
       
       switch (this.lang){
        
            case 'es' : 
                this.iconLang = '/assets/images/flag-MX.svg'
            break;
              case 'en' : 
                this.iconLang = '/assets/images/flag-US.svg'
            break;
       }


        this.title$ = this.store.select(selectNavTitle);

        // this.prevRoute$ = this.store.select(selectNavPrevRoute).pipe(
        //     takeUntil(this.destroy$),
        //     filter((r) => r != ['/main/home'])
        // );
        this.store
            .select(selectNavTitle)
            .pipe(delay(1))
            .subscribe((c) => {
                if (window.innerWidth < 1200 && this.menuOpen) {
                    this.menuOpen = false;
                    this.menuToggled.emit(this.menuOpen);
                }
            });

        this.store
            .select(selectAuthState)
            .pipe(takeUntil(this.destroy$))
            .subscribe((c) => {
                if (c.user_data != null) {
                    if (c.role_info != null) {
                        this.hiddeControls = true;
                        this.authServ
                            .getNotificationsUser(
                                c.role_info.role,
                                c.user_data.uid
                            )
                            .then((c) => {
                                this.notifications = c;
                            });
                    }
                } else {
                    this.hiddeControls = false;
                }
            });
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
    }
    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    public onBurguerClick() {
        this.menuOpen = !this.menuOpen;
        this.menuToggled.emit(this.menuOpen);
    }

    public onBackClick(route: string[]) {
        this.store.dispatch(navigate({ url: route }));
    }

    openNotifications(): void {}

    singOut() {
        this.store.dispatch(signOutUser());
    }
    login() {
        this.store.dispatch(navigate({ url: ['auth', 'login'] }));
    }
    ChangeLanguage(lang : string){
            this.storage.setItem('lang',lang)
            this.traslateServ.use(lang)
            window.location.reload();
    }
}
