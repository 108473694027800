import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DistributorsService } from '../../services/distributors/distributors.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import {
    createDistributor,
    deleteSelected,
    getDistributors,
    restoreDistributor,
    setDistributors,
    updateSelected,
} from '../actions/distrubutors.actions';
import { navigate } from '../actions/navigation.actions';
import { showNotification } from '../actions/notifications.actions';
import { selectDirectoryState } from '../selectors/directory.selectors';
import { selectedDistributors } from '../selectors/distributors.selector';

@Injectable()
export class DistributorsEffects {
    constructor(
        private actions$: Actions,
        private distributorsService: DistributorsService ,       private localStorage: LocalStorageService,
        private store: Store,

    ) {}
    public persistDistributosState$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setDistributors),
                switchMap(() => this.store.select(selectDirectoryState)),
                map((state) => this.localStorage.setItem('distributors', state ))
            );
        },
        {
            dispatch: false,
        }
    );
    public getDirectoryUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getDistributors),
            switchMap(() => this.distributorsService.getDistributors()),
            map((distributors) => setDistributors({ distributors }))
        );
    });
    public createDistributor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createDistributor),
            switchMap(({ selected }) =>
                from(this.distributorsService.createDistributor(selected))
            ),
            filter((u) => u == true),
            switchMap(() => [
                navigate({ url: ['main', 'distributors'] }),
                showNotification({
                    message: 'Usuario creado con éxito',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });    
    public restoreDistributor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreDistributor),
            switchMap(({ distributors }) =>
                from(this.distributorsService.restoreDistributors(distributors))
            ),
            filter((u) => u == true),
            switchMap(() => [
                getDistributors(),
                showNotification({
                    message: 'Restaurada con éxito',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    public updateDistributor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateSelected),
            switchMap(({ selected }) =>
                from(this.distributorsService.updateDistributors(selected))
            ),
            filter((u) => u == true),
            switchMap(() => [
                navigate({ url: ['main', 'distributors'] }),
                showNotification({
                    message: 'Usuario actualizado ',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    public deleteUserDirectory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteSelected),
            switchMap(({ selected }) =>
                from(this.distributorsService.deleteDistributor(selected))
            ),
            filter((u) => u == true),
            switchMap(() => [
                getDistributors(),
                showNotification({
                    message: 'Usuario Eliminado',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
}
