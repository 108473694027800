import { Action, createReducer, on } from '@ngrx/store';
import { getNews, setNews } from '../actions/news.actions';
import { defaultNewState, NewsState } from '../states/news.state';

const reducer = createReducer(
    defaultNewState,
    on(setNews, (state: NewsState, { newsList }) => ({
        ...state,
        loading: false,
        newsList,
    })),
    on(getNews, (state) => ({ ...state, loading: true }))
);

export function newsReducer(state: NewsState, action: Action) {
    return reducer(state, action);
}
