import { createAction, props } from '@ngrx/store';
import { blogModel } from '../../models/blog/blog.model';

export const setBlogs = createAction(
    '[Blog] Set blogs',
    props<{ Blogs: blogModel[] }>()
);

export const getBlogs = createAction('[Blog] Get blogs');

export const setSelectedBlog = createAction(
    '[Blog] Set Selected blog',
    props<{ selectedBlog: blogModel }>()
);

export const updateBlog = createAction(
    '[Blog] update Views Counter ',
    props<{ selectedBlog: blogModel }>()
);

export const deleteBlog = createAction(
    '[Blog] delete blog selected ',
    props<{ selectedBlog: blogModel }>()
);
export const removeSelected = createAction('[Blog] Remove selected blog ');

export const createBlog = createAction(
    '[Blog] Create blog',
    props<{ selectedBlog: blogModel }>()
);

export const blogLoader = createAction(
    '[Blog] Loaded blog action ',
    props<{ loading: blogModel }>()
);
export const searchBlogByTitle = createAction(
    '[Blog] Loade blog data ',
    props<{ title: string }>()
);
