import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import { SieUser } from 'src/app/core/models/auth/user.model';
import { IRegistryPersonalInfo } from '../../models/auth/personal_info.model';
import { roles } from '../../models/auth/roles.model';

import {
    setRegistryPersonalInfo,
    setRolePersonalInfo,
    throwAuthError,
} from '../../store/actions/auth.actions';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private firesbaseAuth: AngularFireAuth,
        private store: Store,
        private db: AngularFirestore
    ) {}

    public async signUpUser(user: IRegistryPersonalInfo) {
        return this.firesbaseAuth
            .createUserWithEmailAndPassword(user.email, user.pass)
            .then(async (u) => {
                await u.user.sendEmailVerification().then((c) => true);
                await this.setUserInfo(u.user.uid, user)
                    .then((c) => true)
                    .catch((err) => false);
                return new SieUser(u.user);
            })
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: e.code }));
                return null;
            });
    }

    public async sendVerificationEmail() {
        return this.firesbaseAuth.currentUser
            .then((u) =>{
                // console.log(u)
                u.sendEmailVerification()
                return true 
            }
            )
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }

    public async loginUser(email: string, pass: string) {
        return this.firesbaseAuth
            .signInWithEmailAndPassword(email, pass)
            .then(async (u) => {
                let user: any = await this.db
                    .collection('user_info')
                    .doc(u.user.uid)
                    .ref.get()
                    .then((c) => c.data());

                const info: IRegistryPersonalInfo = {
                    name: user.name,
                    last_names: user.last_names,
                    birth: user.birth,
                    email: user.email,
                    country: user.country,
                    state: user.state,
                    city: user.city,
                    company: user.company,
                    title: user.title,
                    role: user.role,
                };

                let role_info = await this.db
                    .collection('users_roles')
                    .doc(info.role)
                    .ref.get()
                    .then((c) => new roles(c.data()));

                this.store.dispatch(setRolePersonalInfo({ role_info }));
                this.store.dispatch(setRegistryPersonalInfo({ info }));
                return new SieUser(u.user);
            })
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: e.code }));
                return null;
            });
    }

    public async sendPasswordResetEmail(email: string) {
        return this.firesbaseAuth
            .sendPasswordResetEmail(email)
            .then(() => true)
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: e.code }));
                return false;
            });
    }

    public async signOutuser() {
        return this.firesbaseAuth
            .signOut()
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }

    public async setUserInfo(uid: string, user: IRegistryPersonalInfo) {
        return this.db
            .collection('user_info')
            .doc(uid)
            .set(user)
            .then(() => {
                return true;
            })
            .catch((e) => {
                return false;
            });
    }

    public async getNotificationsUser(role: string, uid: string) {
        let notifications: any[] = [];
        await this.db
            .collection('notifications')
            .ref.where('role', '==', 'all')
            .get()
            .then((c) =>
                c.docs.map((doc) => {
                    notifications.push({ id: doc.id, data: doc.data() });
                })
            );
        await this.db
            .collection('notifications')
            .ref.where('role', '==', role)
            .get()
            .then((c) =>
                c.docs.map((doc) => {
                    notifications.push({ id: doc.id, data: doc.data() });
                })
            );
        await this.db
            .collection('notifications')
            .ref.where('user', '==', uid)
            .get()
            .then((c) =>
                c.docs.map((doc) => {
                    notifications.push({ id: doc.id, data: doc.data() });
                })
            );

        return notifications;
        //             )
        //             .subscribe((c) =>
        //                 c.docs.map((c) => ({ id: c.id, data: c.data() }))
        //             );
    }
}
