import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor( private httpClient : HttpClient) { }

 async  translate(text: string = ""){
    return await this.httpClient.post('https://translation.googleapis.com/language/translate/v2?key=AIzaSyDF8WwOBt4XF4Qxc2ZEsaz5gT34QxUYq7Y',{
      "q": text,
      "target": "en"
    }).toPromise().then((c : any) => c.data.translations[0].translatedText)
  }
}
