import { Action, createReducer, on } from '@ngrx/store';

import { loadCountries, loadCountryStates, setCountries, setCountryStates } from '../actions/geoloc-lookups.actions';
import { defaultGeolocationState, GeolocationState } from '../states/geoloc-lookups.state';

const reducer = createReducer(
    defaultGeolocationState,
    on(loadCountries, (state: GeolocationState) => ({
        ...state,
        loading: true,
    })),
    on(setCountries, (state: GeolocationState, { countries }) => ({
        ...state,
        loading: false,
        countries,
    })),
    on(loadCountryStates, (state: GeolocationState) => ({
        ...state,
        loading: true,
    })),
    on(setCountryStates, (state: GeolocationState, { states }) => ({
        ...state,
        loading: false,
        country_states: states,
    }))
);

export function geolocationReducer(state: GeolocationState, action: Action) {
    return reducer(state, action);
}
