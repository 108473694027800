import { Action, createReducer, on } from '@ngrx/store';
import {
    cleanProduct,
    createProduct,
    deleteCatalogue,
    deleteProduct,
    getCataloguesCategories,
    getProductsList,
    saveCategory,
    setCataloguesCategories,
    setProductsList,
    setSelectedCategory,
    setSelectedProduct,
    updateProduct,
} from '../actions/catalogues.actions';
import {
    CataloguesState,
    defaultCataloguesState,
} from '../states/catalogues.state';

// import {
//     getCategoryCourses,
//     getProductCategories,
//     setCategoryCourses,
//     setProductCategories,
//     setSelectedCategory,
// } from '../actions/training.actions';
// import { defaultTrainingState, TrainingState } from '../states/training.state';

const reducer = createReducer(
    defaultCataloguesState,
    on(getCataloguesCategories, (state: CataloguesState) => ({
        ...state,
        loading: true,
    })),
    on(
        setCataloguesCategories,
        (state: CataloguesState, { clasificationCategories }) => ({
            ...state,
            clasificationCategories,
            loading: false,
        })
    ),
    on(getProductsList, (state: CataloguesState) => ({
        ...state,
        loading: true,
    })),
    on(setProductsList, (state: CataloguesState, { products }) => ({
        ...state,
        products,
        loading: false,
    })),
    on(setSelectedCategory, (state: CataloguesState, { selectedclasification }) => ({
            ...state,
            selectedclasification,
        loading  : true
        
        })),
    on(saveCategory, (state: CataloguesState, { selectedclasification }) => ({
        ...state,
        selectedclasification,
        loading: true,
    })),
    on(setSelectedProduct, (state: CataloguesState, { selectedProduct }) => ({
        ...state,
        selectedProduct,
    })),
    on(updateProduct, (state: CataloguesState, { selectedProduct }) => ({
        ...state,
        selectedProduct,
    })),
    on(createProduct, (state: CataloguesState, { selectedProduct }) => ({
        ...state,
        selectedProduct,
    })),
    on(deleteProduct, (state: CataloguesState, { selectedProduct }) => ({
        ...state,
        selectedProduct,
    })),    
    on(deleteCatalogue, (state: CataloguesState, { selectedclasification }) => ({
        ...state,
        selectedclasification,
    })),
    on(cleanProduct, (state: CataloguesState) => ({
        ...state,
        selectedProduct: null,
    }))
);

export function cataloguesReducer(state: CataloguesState, action: Action) {
    return reducer(state, action);
}
