import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/blog.actions';
import { BlogsState, defaultBlogState } from '../states/blog.state';

const reducer = createReducer(
    defaultBlogState,
    on(Actions.getBlogs, (state: BlogsState) => ({
        ...state,
        loading: true,
    })),
    on(Actions.setBlogs, (state: BlogsState, { Blogs }) => ({
        ...state,
        Blogs,
        loading: false,
    })),
    on(Actions.setSelectedBlog, (state: BlogsState, { selectedBlog }) => ({
        ...state,
        selectedBlog,
        loading: false,
    })),
    on(Actions.createBlog, (state: BlogsState, { selectedBlog }) => ({
        ...state,
        selectedBlog,
        loading: true,
    })),
    on(Actions.updateBlog, (state: BlogsState, { selectedBlog }) => ({
        ...state,
        selectedBlog,
        loading: false,
    })),
    on(Actions.deleteBlog, (state: BlogsState, { selectedBlog }) => ({
        ...state,
        selectedBlog,
        loading: false,
    })),
    on(Actions.removeSelected, (state: BlogsState) => ({
        ...state,
        selectedBlog: null,
        loading: false,
    }))
);

export function blogReducer(state: BlogsState, action: Action) {
    return reducer(state, action);
}
