import { Action, createReducer, on } from '@ngrx/store';
import {
    loginUser,
    sendEmailVerification,
    sendRecoveryEmail,
    sentEmail,
    setRegistryPersonalInfo,
    setRolePersonalInfo,
    setUserData,
    signOutUser,
    signUpUser,
    throwAuthError,
} from '../actions/auth.actions';
import { AuthState, defaultAuthState } from '../states/auth.state';

const reducer = createReducer(
    defaultAuthState,
    on(loginUser, (state: AuthState) => ({
        ...state,
        loading: true,
    })),
    on(setUserData, (state: AuthState, { user_data }) => ({
        ...state,
        user_data: user_data,
        loading: false,
    })),
    on(setRegistryPersonalInfo, (state: AuthState, { info }) => ({
        ...state,
        registry: {
            personal_info: { ...info },
        },
    })),
    on(signUpUser, (state: AuthState) => ({
        ...state,
        loading: true,
    })),
    on(signOutUser, (state: AuthState) => ({
        ...state,
        loading: true,
        user_data: null,
        registry: {
            personal_info: null,
        },
        role_info: null,
    })),
    on(setRolePersonalInfo, (state: AuthState, { role_info }) => ({
        ...state,
        role_info: role_info,
        loading: true,
    })),
    on(sendEmailVerification, (state: AuthState) => ({
        ...state,
        loading: true,
    })),
    on(sentEmail, (state: AuthState) => ({
        ...state,
        loading: false,
    })),
    on(throwAuthError, (state: AuthState, { code }) => ({
        ...state,
        loading: false,
    })),
    on(sendRecoveryEmail, (state: AuthState) => ({
        ...state,
        loading: true,
    }))
);

export function authReducer(state: AuthState, action: Action) {
    return reducer(state, action);
}
