export class ProductCategory {
    id: string;
    title: string;
    description: string;
    courses: Partial<CategoryCourse>[];
    duration?: Number;
    courseCount?: Number;

    constructor(item: any, id: any) {
        this.id = id || item.id;
        this.title = item.title;
        this.description = item.description;
        this.courses = item.courses.map((v: any) => new CategoryCourse(v));
        this.duration = this.courses
            .map((c) => c.duration)
            .reduce((a, b) => a + b);
        this.courseCount = this.courses.length;
    }
}

export class CategoryCourse {
    name: string;
    duration: number;
    description: string;
    url: string;

    constructor(item: any) {
        this.name = item.name;
        this.duration = Number(item.duration);
        this.description = item.description;
        this.url = item.url;
    }
}
