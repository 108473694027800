import { Action, createReducer, on } from '@ngrx/store';
import {
    findProduct,
    getPriceList,
    getPriceListData,
    setFamilieSelected,
    setPriceList,
    setPriceListData,
    setProductSelected,
    setsubFamilieSelected,
    updatePriceList,
} from '../actions/price-list.actions';

import {
    defaultPriceListState,
    price_listState,
} from '../states/price-list.state';

const reducer = createReducer(
    defaultPriceListState,
    on(getPriceList, (state: price_listState) => ({
        ...state,
        loading: true,
    })),
    on(setPriceList, (state: price_listState, { price_list }) => ({
        ...state,
        price_list,
        loading: false,
    })),
    on(updatePriceList, (state: price_listState, { price_list }) => ({
        ...state,
        price_list,
        loading: true,
    })),
    on(getPriceListData, (state: price_listState,{id}) => ({
        ...state,
        id,
        loading: true,
    })),
    on(setPriceListData, (state: price_listState, { price_list_data }) => ({
        ...state,
        price_list_data,
        loading: false,
    })),
    on(setFamilieSelected, (state: price_listState, { familieSelected }) => ({
        ...state,
        familieSelected,
        loading: false,
    })),
    on(setsubFamilieSelected, (state: price_listState, { subFamilieSelected }) => ({
        ...state,
        subFamilieSelected,
        loading: false,
    })),
    on(setProductSelected, (state: price_listState, { productoSelected }) => ({
        ...state,
        productoSelected,
        loading: false,
    })),
      on(findProduct, (state: price_listState, { searchValue }) => ({
        ...state,
        searchValue,
        loading: false,
    }))
);

export function priceListReducer(state: price_listState, action: Action) {
    return reducer(state, action);
}
