import { createAction, props } from '@ngrx/store';
import { DistributorModel } from '../../models/distributors/distributor.model';

export const getDistributors = createAction('[Distributors] Get Distributors');

export const setCountries = createAction(
    '[Geolocation] Set Countries',
    props<{ countries: DropdownOption[] }>()
);

export const setDistributors = createAction(
    '[Geolocation] Set Distributors',
    props<{ distributors: DistributorModel[] }>()
);

export const restoreDistributor = createAction(
    '[Distributors] Restore Distributors',
    props<{ distributors: DistributorModel[] }>()
);

export const createDistributor = createAction(
    '[Distributors] Create Distributor',
    props<{ selected: DistributorModel }>()
);

export const setSelected = createAction(
    '[Distributors] Select Distributor',
    props<{ selected: DistributorModel }>()
);

export const updateSelected = createAction(
    '[Distributors] Update Distributor',
    props<{ selected: DistributorModel }>()
);
export const deleteSelected = createAction(
    '[Distributors] Delete Distributor',
    props<{ selected: DistributorModel }>()
);
