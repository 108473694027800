import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'si-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
    @Input() images: Array<string> = [
        'assets/images/image1.jpg',
        'assets/images/image2.jpg',
        'assets/images/image3.jpg',
    ];
    @Input() controls: boolean = true;
    @Input() Modelo3D : string = null;
    public currentPosition = 0;
    public slideMargin = '0';
    public lenghtSlide = 0;
    constructor() {}

    ngOnInit(): void {
        this.lenghtSlide = this.images.length - 1 + ( this.Modelo3D ? 1 : 0 )
        // setInterval(() => {
        //     this.onNextClick();
        // }, 5000);
    }
    public onNextClick() {
        if (this.currentPosition == this.lenghtSlide) {
            this.currentPosition = 0;
        } else {
            this.currentPosition = this.currentPosition + 1;
        }
        this.slideMargin = `${-100 * this.currentPosition}%`;
    }

    public onPrevClick() {
       
        if (this.currentPosition <= 0) {
            this.currentPosition = this.lenghtSlide;
        } else {
            this.currentPosition = this.currentPosition - 1;
        }
        this.slideMargin = `${-100 * this.currentPosition}%`;
    }
}
