import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector,
} from '@ngrx/store';

import { AuthState } from '../states/auth.state';

export const selectAuthState: MemoizedSelector<object, AuthState> =
    createFeatureSelector<AuthState>('auth');

export const selectUserData = createSelector(
    selectAuthState,
    (state: AuthState) => state.user_data
);

export const selectAuthLoading = createSelector(
    selectAuthState,
    (state: AuthState) => state.loading
);

export const selectAuthRegistry = createSelector(
    selectAuthState,
    (state: AuthState) => (state.registry ? state.registry : null)
);

export const selectUserRole = createSelector(
    selectAuthState,
    (state: AuthState) => state.role_info
);

export const selectUserExtraInfo = createSelector(
    selectAuthState,
    (state: AuthState) => state.registry.personal_info
);

export const selectAuthor = createSelector(
    selectAuthState,
    (state: AuthState) => ({
        id: state.user_data.uid,
        name: state.registry.personal_info.name,
        email: state.registry.personal_info.email,
        position: state.registry.personal_info.title,
    })
);
