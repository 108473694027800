export class Price_list {
    id?: string;
    lastedUpdate: Date;
    urlFile: string;
    constructor(id: any, item: any) {
        this.id = id;
        this.lastedUpdate = item.lastedUpdate;
        this.urlFile = item.urlFile;
    }
}


export class PriceListDataModel{
        family: any[];
        product_manager: any[];
        products: any[];
        subfamily: any[];
        table_headers: any[];
        table_info: any[];
        constructor(  family: any[],
            product_manager: any[],
            products: any[],
            subfamily: any[],
            table_headers: any[],
            table_info: any[]){
                this.family = family;
                this. product_manager = product_manager;
                this. products = products;
                this. subfamily = subfamily;
                this. table_headers = table_headers;
                this. table_info = table_info;

        }
}