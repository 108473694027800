export class roles {
    role: string;
    sections: string[];
    permissions: string[];
    constructor(data: any) {
        this.role = data.role;
        this.sections = data.sections;
        this.permissions = data.permissions;
    }
}
