import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { NavigationState } from '../states/navigation.state';

export const selectNavState: MemoizedSelector<
    object,
    NavigationState
> = createFeatureSelector<NavigationState>('navigation');

export const selectNavigating = createSelector(
    selectNavState,
    (state: NavigationState) => state.navigating
);

export const selectNavTitle = createSelector(
    selectNavState,
    (state: NavigationState) => state.currentTitle
);

export const selectNavPrevRoute = createSelector(
    selectNavState,
    (state: NavigationState) => state.prevRoute
);
