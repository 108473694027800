import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { API_URLS } from '../../constants/api-urls';
import { selectUserRole } from '../../store/selectors/auth.selectors';

@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private db: AngularFirestore,
        private auth: AngularFireAuth,
        private http: HttpClient,
        private store: Store
    ) {
        this.angularFireMessaging.messages.subscribe((_messaging: any) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh =
                _messaging.onTokenRefresh.bind(_messaging);
        });
    }

    requestPermission() {
        this.auth.authState.pipe(take(1)).subscribe(async (user) => {
            if (!user) return;

            this.angularFireMessaging.requestToken.subscribe(
                (token) => {
                    // console.log(token)
                    this.store
                        .select(selectUserRole)
                        .pipe(take(1))
                        .subscribe((role) => {
                            this.db
                                .collection('fcmTokens')
                                .doc(user.uid)
                                .set({ token: token, role: role.role });
                        });
                },
                (err) => {
                    console.error('Unable to get permission to notify.', err);
                }
            );
        });
    }
    receiveMessage() {
        return this.angularFireMessaging.messages;
        // return this.angularFireMessaging.onBackgroundMessage;
    }

    async sendMessage(data = {}) {
        let token = await this.auth.currentUser.then((u) =>
            u.getIdToken().then((t) => t)
        );

        return this.http
            .post(API_URLS.BASE_URL, data, {
                headers: new HttpHeaders().set('Authorization', token),
                observe: 'response',
            })
            .toPromise();
    }

    getallnotifications(): Observable<any[]> {
        return this.db
            .collection('notifications')
            .get()
            .pipe(map((res) => res.docs.map((doc) => {
                let item = doc.data();
                item['id'] = doc.id
                return item;
            })))
                // return id : doc.id, ...doc.data()}));
    }
    async deleteNotification(notiid : string = ""){
        return await this.db
            .collection('notifications').doc(notiid).delete().then(c=> true).catch(err=> false);
            // .pipe(map((res) => res.docs.map((doc) => (doc.id, doc.data()))));
    }

}
