import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { observable, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private storage: AngularFireStorage) {}

    pushFileToStorage(file: File, basePath = '/uploads'): Observable<string> {
        return new Observable((observer) => {
            const filePath = `${basePath}/${file.name}`;
            const storageRef = this.storage.ref(filePath);
            const uploadTask = this.storage.upload(filePath, file);

            uploadTask
                .snapshotChanges()
                .pipe(
                    finalize(() => {
                        storageRef.getDownloadURL().subscribe((downloadURL) => {
                            observer.next(downloadURL);
                        });
                    })
                )
                .subscribe();
        });
    }

    getNameFile(url: string) {
        return this.storage.refFromURL(url).getMetadata();
    }
}
