import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { IRegistryPersonalInfo } from '../../models/auth/personal_info.model';
import { Products } from '../../models/catalogues/catalogues-products.model';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    async updateUserInfo(user: IRegistryPersonalInfo) {
        return this.db
            .collection('user_info')
            .doc(user.id)
            .set({ ...user })
            .then((c) => {
                return true;
            })
            .catch((err) => {
                return false;
            });
    }
    async deleteUserInfo(user: IRegistryPersonalInfo) {
        return this.db
            .collection('user_info')
            .doc(user.id)
            .delete()
            .then((c) => {
                return true;
            })
            .catch((err) => {
                return false;
            });
    }
    constructor(private db: AngularFirestore) {
    //   this.db =  this.db.collection("test")
    }

    public getUsersRegister(): Observable<IRegistryPersonalInfo[]> {
        return this.db
            .collection('user_info')
            .get()
            .pipe(
                map((c) =>
                    c.docs.map((doc: any) => ({
                        id: doc.id,
                        name: doc.data().name,
                        last_names: doc.data().last_names,
                        birth: doc.data().birth,
                        email: doc.data().email,
                        country: doc.data().country,
                        state: doc.data().state,
                        city: doc.data().city,
                        company: doc.data().company,
                        title: doc.data().title,
                        role: doc.data().role,
                    }))
                )
            );
    }

    async questionPending() {
        let products: Products[] = [];
        await this.db
            .collection('products')
            .ref.where('faqs', '!=', '')
            .get()
            .then((c) =>
                c.forEach((doc: any) =>
                    products.push(new Products(doc.id, doc.data()))
                )
            );

        let data = products.filter((product) => {
            if (product.faqs.some((c) => c.answer === undefined)) {
                return product;
            }
        });

        return data;
    }
}
