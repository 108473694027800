import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'si-dropdown-menu',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit {
    @HostBinding('class.dropdown-input') inputClass = true;
    @Input() options: Array<DropdownOption> = [];
    @Input() label: string = 'Menú';
    @Output() change = new EventEmitter();
    public selectedOption: string = 'Todos';
    public toggle: boolean;
    public value: DropdownOption;
    constructor() {}

    ngOnInit(): void {}
    public onOptionSelected(value: any) {
        this.value = this.options.find((o) => o.value === value);
        this.toggle = false;
        this.change.emit(this.value.value);
        this.selectedOption = this.value.label;
    }
    public onInputClick() {
        this.toggle = !this.toggle;
    }
}
