<section class="user-info" *ngIf="user_data_extra | async as user_data">
    <h2>{{ user_data.name }}</h2>
    <p style="padding: 0 1rem">
        {{ user_data.title }} -
        <span>{{ user_data.company }}</span>
    </p>
    <span class="user-role-badge" *ngIf="role$ | async as role">{{
        role.role
    }}</span>
</section>
<section class="user-info" *ngIf="!user_data">
    <p>{{ 'main.welcome'  | translate }}</p>
</section>

<section class="user-actions" *ngIf="user_data">
    <i class="btn-icon icon-notification"></i>
    <i class="btn-icon icon-user"></i>
    <i class="btn-icon icon-logout" (click)="singOut()"></i>
</section>
<section class="nav-links">
    <a
        *ngFor="let link of menuLinks"
        class="nav-link movile--click"
        (click)="goTo(link.link)"
        routerLinkActive="active "
    >
        <i [class]="link.icon"></i>
        {{ link.name |translate }}
    </a>
</section>
<img src="/assets/images/Ep_hub.png" alt="icon ep-hub" loading="lazy" width="150" height="" />

<div class="icons">
    <i
        class="icon-facebook"
        (click)="Open('https://www.facebook.com/groups/electricalProductsMX/')"
    ></i>
    <i
        class="icon-instagram"
        (click)="Open('https://www.instagram.com/siemens/?hl=es-la')"
    ></i>
    <i
        class="icon-linkedin"
        (click)="Open('https://www.linkedin.com/company/siemens')"
    ></i>
</div>

<!-- <i class="icon-sie-logo"></i> -->
