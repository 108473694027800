export class Products {
    id?: string;
    name: string;
    description: string;
    code: string;
    Modelo3D : string;
    img_presentation: Partial<string[]>;
    contacto: Partial<Author[]>;
    areas: Partial<string[]>;
    downloads: Partial<downloads[]>;
    families: Partial<string[]>;
    features: Partial<spec[]>;
    normatives: Partial<string[]>;
    types: Partial<string[]>;
    status: boolean;
    faqs: Partial<faqs[]>;

    constructor(id: any, item: any) {
        this.id = id;
        this.name = item.name;
        this.description = item.description;
        this.img_presentation = item.img_presentation
        this.areas = item.areas;
        this.contacto = item.contacto || [];
        this.downloads = item.downloads ? item.downloads.map((c: any) => new downloads(c)) : []; //item.courses.map((v: any) => new CategoryCourse(v));
        this.families = item.families;
        this.features = item.features ? item.features.map((c: any) => new spec(c))  : [];
        this.normatives = item.normatives;
        this.status = item.status;
        this.types = item.types;
        this.faqs = item.faqs ? item.faqs.map((c: any) => new faqs(c)) : [];
    }
}

export class spec {
    name: string;
    value: string;
    constructor(item: any) {
        this.name = item.name;
        this.value = item.value;
    }
}
export class faqs {
    question: string;
    answer: string;
    createAt: Date;
    author: Author;
    constructor(item: any) {
        this.question = item.question;
        this.answer = item.answer;
        this.createAt = item.createAt;
        this.author = item.author;
    }
}

export class Author {
    id?: string;
    name?: string;
    email: string;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.email = data.email;
    }
}

export class downloads {
    name: string;
    description: string;
    url: string;
    constructor(item: any) {
        this.name = item.name;
        this.description = item.description;
        this.url = item.url;
    }
}
export class ProductsModel{
    id : string ;
    title : string ;
    descripcion : string ;
    img_presentation : string ;
    normatives : string ;
    families : string ;
    aplicationAreas : string ;
    types : string ;
    contact_name : string ;
    contact_email : string ;
    code : string ;
    constructor(){

    }
}