import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ErrorCodesService } from '../../services/error-codes/error-codes.service';
import { throwAuthError } from '../actions/auth.actions';
import {
    hideNotification,
    showNotification,
} from '../actions/notifications.actions';

@Injectable()
export class NotificationsEffects {
    constructor(
        private actions$: Actions,
        private errorCodeService: ErrorCodesService
    ) {}
    showNotification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showNotification),
            switchMap(() => timer(3500)),
            map(() => hideNotification())
        );
    });

    showErrorNotification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(throwAuthError),
            map(({ code }) =>
                showNotification({
                    alertType: 'error',
                    message: this.errorCodeService.mapErrorCode(code),
                    component: 'alert',
                })
            )
        );
    });
}
