import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ProjectsService } from '../../services/projects/projects.service';
import { navigate } from '../actions/navigation.actions';
import {
    hideNotification,
    showNotification,
} from '../actions/notifications.actions';
import {
    cleanSelected,
    createProject,
    deleteProject,
    getProjects,
    requestProject,
    setProjects,
    updateProject,
} from '../actions/projects.actions';

@Injectable()
export class ProjectsEffects {
    constructor(
        private actions$: Actions,
        private projectServ: ProjectsService
    ) {}

    public getProjectsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjects),
            switchMap(() => this.projectServ.getProjectsList()),
            map((projectsList) => setProjects({ projectsList }))
        );
    });

    public createNewProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProject),
            switchMap(({ selectedProject }) =>
                from(this.projectServ.createProject(selectedProject))
            ),
            filter((c) => c == true),
            switchMap(() => [navigate({ url: ['main', 'projects'] })])
        );
    });
    public updateProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProject),
            switchMap(({ selectedProject }) =>
                from(this.projectServ.updateProject(selectedProject))
            ),
            filter((c) => c == true),
            switchMap(() => [
                cleanSelected(),
                hideNotification(),
                navigate({ url: ['main', 'projects'] }),
            ])
        );
    });
    public deleteProjectSelected$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteProject),
            switchMap(({ selectedProject }) =>
                from(this.projectServ.deleteProject(selectedProject))
            ),
            filter((c) => c == true),
            switchMap(() => [
                cleanSelected(),
                showNotification({
                    message: 'Se ha eliminado exitosamente',
                    alertType: 'success',
                    component: 'alert',
                }),
                getProjects(),
            ])
        );
    });

    public sendRequestProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(requestProject),
            switchMap(({ request }) =>
                from(this.projectServ.sendRequest(request))
            ),
            filter((c) => c == true),
            switchMap(() => [
                showNotification({
                    message: 'Se ha enviado tu solicitud exitosamente ',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
}
