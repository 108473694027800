import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector,
} from '@ngrx/store';
import { BlogsState } from '../states/blog.state';

export const selectBlogState: MemoizedSelector<object, BlogsState> =
    createFeatureSelector<BlogsState>('blog');

export const selectBlogs = createSelector(
    selectBlogState,
    (state: BlogsState) => state.Blogs
);

export const selectBlogLoading = createSelector(
    selectBlogState,
    (state: BlogsState) => state.loading
);

export const selectSelectedBlog = createSelector(
    selectBlogState,
    (state: BlogsState) => state.selectedBlog
);
