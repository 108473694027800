<div class="badge" *ngIf="removed == false">
    <div>
        <div>
            <img
                _ngcontent-kgy-c97=""
                src="assets/images/image.svg"
                alt="image"
            />
            <p>{{ file.name }}</p>
        </div>

        <span *ngIf="downloadURL as url" (click)="delete()"> X </span>
    </div>
    <div *ngIf="percentage | async as pct">
        <progress [value]="pct" max="100"></progress>
        {{ pct | number }}%
    </div>
</div>

<div *ngIf="snapshot | async as snap">
    <!-- {{ snap.bytesTransferred }} of {{ snap.totalBytes }} -->
    <div *ngIf="downloadURL as url">
        <!-- <h3>cargado con exito!</h3> -->
    </div>
</div>
