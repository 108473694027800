// import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sortByNumberValue } from 'src/app/core/functions/sorting.functions';
import { selectPriceListData } from 'src/app/core/store/selectors/price-list.selectors';

import {
    imagePortada,
    imgAtencion,
    imgGuiaAviso,
    placeholder,
    imgDescubre,
    imgDistribuidores,
} from './baseimages';

import Swal from 'sweetalert2';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.pageLayout = {
    height: 792,
    width: 612,
    margins: [40, 100, 80, 80],
};
@Component({
    selector: 'si-pdf-price-list',
    templateUrl: './pdf-price-list.component.html',
    styleUrls: ['./pdf-price-list.component.scss'],
})
export class PdfPriceListComponent implements OnInit {
    constructor(private store: Store) {}

    public enable: boolean = false;
    private destroy$ = new Subject();
    public htmlContent = {
        pageSize: 'LETTER',
        info: {
            title: 'Lista de Precios Ep-Hub-MX',
            author: 'Siemens Mexico',
            creator: 'Siemens Mexico',
            keywords: 'keywords for document',
        },

        content: [
            {
                image: imagePortada,
                width: 620,
                margin: [-40, -40, 0, 0],
            },
            {
                // margin: [-35, 10, 0, 0],
                image: imgGuiaAviso,
                width: 630,
                pageBreak: 'before',
                margin: [-40, -40, 0, 0],
            },
            {
                margin: [-40, -40, 0, 0],
                image: imgAtencion,
                width: 630,
                pageBreak: 'before',
            },
            {
                text: 'Lista de Precios de EP',
                style: 'title',
                pageBreak: 'before',
                section: '',
            },
            {
                columns: [
                    {
                        text: [],
                    },
                    {
                        image: '',
                        alignment: 'right',
                        margin: [-35, 10, 0, 0],
                        fit: [],
                    },
                ],
            },
            {
                text: '',
                style: '',
            },
            'CONTROL Y DISTRIBUCIÓN DE BAJA TENSIÓN 2022',
            {
                style: 'tableExample',
                // headerRows: 2,
                // dontBreakRows: true,
                // keepWithHeaderRows: true,
                table: {
                    body: [[], [], []],
                },
                layout: '',
            },
            {},
        ],
        styles: {
            title: {
                fontSize: 14,
                bold: true,
            },
            header: {
                fontSize: 12,
                bold: true,
            },
            subheader: {
                fontSize: 10,
                bold: true,
            },
            small: {
                fontSize: 8,
                bold: true,
                margin: [15, 10, 0, 5],
                // lineHeight: .5,
            },

            tableExample: {
                fontSize: 8,
                // margin: [0, 5, -10, 15],
                with: '100%',
                alingment: 'center',
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
            },
            description: {
                fontSize: 8,
                // bold: true,
                margin: [20, 0, 0, 0],
                // lineHeight: .5,
            },
        },
        defaultStyle: {
            // alignment: 'justify'
        },
        pageBreakBefore: function (
            currentNode,
            followingNodesOnPage,
            nodesOnNextPage,
            previousNodesOnPage
        ) {
            return (
                currentNode.headlineLevel == 1 &&
                followingNodesOnPage.length == 0
            );
        },
        footer: function (currentPage, pageCount, pageSize, page, currentNode) {
            var meses = new Array(
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre'
            );
            var f = new Date();

            // you can apply any logic and return any valid pdfmake element
            if (currentPage > 3 && currentPage < pageCount - 1)
                return {
                    columns: [
                        {
                            text:
                                currentPage % 2 == 0
                                    ? currentPage.toString()
                                    : 'S = Stock Nacional',
                            margin: [10, 10],
                            alignment: 'left',
                            fontSize: 8,
                            bold: true,
                            width: 130,
                        },
                        {
                            text:
                                'Efectiva a partir :' +
                                f.getDate() +
                                ' de ' +
                                meses[f.getMonth()] +
                                ' del ' +
                                f.getFullYear() +
                                '\n Nos reservamos el derecho de modificar sin previo aviso los precios y datos técnicos de esta lista',
                            margin: [10, 10],
                            fontSize: 6,
                            alignment: currentPage % 2 ? 'left' : 'right',
                            width: 320,
                        },
                        {
                            text:
                                currentPage % 2
                                    ? currentPage.toString()
                                    : 'S = Stock Nacional',
                            margin: [10, 10],
                            alignment: 'right',
                            fontSize: 8,
                            width: 140,
                            bold: true,
                        },
                    ],
                };
            else pageSize['margin'] = [-40, -40, -40, -40];
        },
        header: function (currentPage, pageCount, pageSize, page, currentNode) {
            if (currentPage > 3 && currentPage < pageCount - 1)
                return {
                    columns: [
                        {
                            text: 'SIEMENS',
                            margin: [10, 10],
                            fontSize: 12,
                            bold: true,
                        },
                        {
                            text: '| Lista de precios ',
                            alignment: 'right',
                            margin: [10, 10],
                            fontSize: 8,
                        },
                    ],
                };
        },
    };

    async ngOnInit() {
        this.store
            .select(selectPriceListData)
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (priceList) => {
                let data = priceList;
                if (data.family.length == 0) {
                    return false;
                }

                this.destroy$.next(true);

                let families = sortByNumberValue(
                    data.family,
                    'family_id',
                    true
                );

                let newSubfamily: any[] = [];
                for await (let item of priceList.subfamily) {
                    if (item.subfamily_image) {
                        var imgBase = await this.imageToDataURL(
                            item.subfamily_image
                        );
                        // var imgBase =  '';

                        const newObject = Object.assign({}, item, {
                            image: imgBase,
                        });
                        newSubfamily.push(newObject);
                    } else {
                        const newObject = Object.assign({}, item);
                        newSubfamily.push(newObject);
                    }
                }

                //Primero obtenemos la familias
                families.map(async (fam) => {
                    //Agregamos El nombre de la familia al reporte
                    this.htmlContent.content.push({
                        text: fam.family_name || 'Familia sin nombre',
                        style: 'header',
                        // headlineLevel: 1,
                        pageBreak: 'before',
                        section: 'fam.family_name',
                    });

                    //Obtenemos las subfamilias
                    var subFamiliesId = data.products
                        .filter((c) => c.family_id == fam.family_id)
                        .map((c) => c.subfamily_id);

                    //eliminamos las familias duplicadas
                    let subFamilies = this.removeDuplicates(subFamiliesId);

                    subFamilies.map((c) => {
                        let subFamilie = newSubfamily.find(
                            (f) => f.subfamily_id == c
                        );

                        var image: string = placeholder;

                        if (subFamilie.image) {
                            image = 'data:image/jpeg,' + subFamilie.image;
                        }
                        let layout_subfamili: any = {
                            columns: [
                                {
                                    text: [
                                        {
                                            text:
                                                (subFamilie['subfamily_name'] ||
                                                    '') + `\n`,
                                            style: 'subheader',
                                        },
                                        {
                                            text:
                                                (subFamilie[
                                                    'subfamily_description'
                                                ] || '') + `\n`,
                                            style: 'description',
                                        },
                                        {
                                            text:
                                                (subFamilie['subfamily_type'] ||
                                                    '') + `\n`,
                                            style: 'small',
                                        },
                                    ],
                                },
                                {
                                    image: image,
                                    alignment: 'right',
                                    fit: [50, 50],
                                    margin: [10, 0, 0, 10],
                                },
                            ],
                        };

                        this.htmlContent.content.push(layout_subfamili);

                        //obtenemos los productos relacionados a la familia y subfamilia
                        let products = priceList.products.filter(
                            (c) =>
                                c.family_id == fam.family_id &&
                                c.subfamily_id == subFamilie['subfamily_id']
                        );
                        // Recorremos los productos y asignamos heders e info por los id
                        let ProductsInfo = products.map((item) =>
                            Object.assign({}, item, {
                                header:
                                    priceList.table_headers.filter(
                                        (c) =>
                                            c.table_headers_id ==
                                            item.table_headers_id
                                    )[0] || [],
                                info:
                                    priceList.table_info.filter(
                                        (c) =>
                                            c.table_info_id ==
                                            item.table_info_id
                                    )[0] || [],
                            })
                        );
                        //Obtenemos los headers del primer producto para saber que los headers
                        let headers = Object.keys(ProductsInfo[0].header || []);
                        let columns = [];
                        //recorremos los productos y juntamos la informacion de headers y info
                        let rows = ProductsInfo.map((pro) => {
                            pro.header;
                            pro.info;
                            let newProduct = [];
                            let columnsinternal = [];

                            newProduct.push(pro.product || '');
                            columnsinternal.push({
                                text: 'Product',
                                fillColor: '#000',
                                color: '#fff',
                                bold: true,
                                alignment: 'center',
                            });
                            for (let i = 1; i < headers.length; i++) {
                                let key = '';
                                try {
                                    key =
                                        pro.header[
                                            'header_0' + i
                                        ].toLowerCase();
                                } catch (e) {}

                                columnsinternal.push({
                                    text: key || '',
                                    fillColor: '#000',
                                    color: '#fff',
                                    bold: true,
                                    alignment: 'center',
                                });

                                let value = pro.info['info_0' + i];
                                newProduct.push(value || '/');
                            }
                            newProduct.push(pro.stock || '');
                            columnsinternal.push({
                                text: 'Stock',
                                fillColor: '#000',
                                color: '#fff',
                                bold: true,
                                alignment: 'center',
                            });

                            newProduct.push(`$${pro.price_list}` || '/');

                            columnsinternal.push({
                                text: 'Precio',
                                fillColor: '#000',
                                color: '#fff',
                                bold: true,
                                margin: [0, 0, 10, 0],
                            });

                            columns =
                                columns.length > columnsinternal.length
                                    ? columns
                                    : columnsinternal;
                            return newProduct;
                        });

                        let table = {
                            style: 'tableExample',
                            // headerRows: 1,
                            // dontBreakRows: false,

                            // keepWithHeaderRows: true,
                            table: {
                                headerRows: 1,
                                dontBreakRows: false,
                                keepWithHeaderRows: false,

                                body: [columns, ...rows],
                            },
                            layout: 'lightHorizontalLines',
                        };
                        this.htmlContent.content.push(table);
                        // this.htmlContent.content.push(' ');
                        this.htmlContent.content.push(' ');
                    });

                    //Agregamos las familias al reporte
                });
                this.enable = true;
            });
    }

    async generatePDF() {
       

        if (!this.enable) {
            Swal.fire('Intenta nuevamente....', 'error');
            return false;
        }
        Swal.fire({
            title: 'Espera',
            icon: 'success',
            showConfirmButton: false,
            timer: 6000
          })

        const awaitDara = async ()=>{
            this.htmlContent.content.splice(3, 1);
            this.htmlContent.content.splice(3, 1);
            this.htmlContent.content.splice(3, 1);
            this.htmlContent.content.splice(3, 1);
            this.htmlContent.content.splice(1, 1);
            this.htmlContent.content.push({
                // margin: [-35, 10, 0, 0],
                image: imgDescubre,
                width: 630,
                pageBreak: 'before',
                margin: [-40, -40, 0, 0],
            });
    
            this.htmlContent.content.push({
                // margin: [-35, 10, 0, 0],
                image: imgDistribuidores,
                width: 630,
                pageBreak: 'before',
                margin: [-40, -40, 0, 0],
            });
    
           
    
                
                let data =   pdfMake.createPdf(this.htmlContent);

                const wait = pdfData => new Promise(resolve => {
                    pdfData.getBlob(blob => {
                        let filename =
                        'Price_List_' + new Date().toLocaleDateString('es-MX') + '.pdf';
                        this.saveFile(blob,filename)
                      resolve(blob);
                    });
                  });
                  return await wait(data);

        }

        await awaitDara();
   
        // pdfMake.createPdf(this.htmlContent).download(filename);
    }

     saveFile(blob, filename) {
       
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = filename;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0)
        
      }


    removeDuplicates(arrays) {
        var arrayed = Array.prototype.slice.call(arguments);
        var seen = {};

        return arrayed.reduce(function (acc, cur) {
            cur.forEach(function (item) {
                if (!seen[item]) {
                    acc.push(item);
                    seen[item] = true;
                }
            });
            return acc;
        }, []);
    }

    async imageToDataURL(imageUrl) {
        return new Promise(async (resolve, reject) => {
            let img = await fetch(imageUrl);
            let img2 = await img.blob();
            let bitmap = await createImageBitmap(img2);
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = bitmap.width;
            canvas.height = bitmap.height;
            ctx.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
            return resolve(canvas.toDataURL('image/png'));
        });
    }
    // async imageToDataURL(url) {
    //     return await axios
    //       .get(url, {
    //         responseType: 'arraybuffer'
    //       })
    //       .then(response => Buffer.from(response.data, 'binary').toString('base64'))
    //   }
}
