import { Action, createReducer, on } from '@ngrx/store';

import { getGallery, saveGallery, setGallery } from '../actions/gallery.actions';
import {GalleryState ,defaultGalleryState } from '../states/gallery.state';

const reducer = createReducer(
    defaultGalleryState,
    on(getGallery, (state: GalleryState) => ({
        ...state,
        loading: true,
    })),
    on(setGallery, (state: GalleryState, { gallery }) => ({
        ...state,
        loading: false,
        gallery,
    })),
    on(saveGallery, (state: GalleryState, { image  }) => ({
        ...state,
        loading: false,
        image 
    })),
);

export function galleryReducer(state: GalleryState, action: Action) {
    return reducer(state, action);
}
