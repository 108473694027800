export class blogModel {
    public id: string;
    public title: string;
    public contain: string;
    public author: BlogAuthor;
    public views: number;
    public tags: string;
    public img: string;
    public createAt: Date;

    constructor(id = '', item: any) {
        this.id = id;
        this.title = item.title;
        this.author = item.author;
        this.views = item.views;
        this.tags = item.tags;
        this.img = item.img;
        this.contain = item.contain;
        this.createAt = item.createAt.toDate();
    }
}

export interface BlogAuthor {
    id: string;
    name: string;
    email?: string;
    position?: string;
}
