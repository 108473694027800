import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import newsModel from '../../models/news/news.model';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    constructor(private db: AngularFirestore) {}

    public getNewList(): Observable<newsModel[]> {
        return this.db
            .collection('news')
            .get()
            .pipe(
                map((c) =>
                    c.docs.map((doc) => new newsModel(doc.data(), doc.id))
                )
            );
    }

    async createNew(data: newsModel) {
        return this.db
            .collection('news')
            .add({ ...data })
            .then((c) => {
                return true;
            })
            .catch((err) => {
                return false;
            });
    }
    async deleteNew(data: newsModel) {
        return this.db
            .collection('news')
            .doc(data.id).delete()
            .then((c) => {
                return true;
            })
            .catch((err) => {
                return false;
            });
    }

}
