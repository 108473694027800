import { Action, createReducer, on } from '@ngrx/store';
import {
    hideNotification,
    showMessageNotification,
    showNotification,
} from '../actions/notifications.actions';
import {
    defaultNotificationsState,
    NotificationsState,
} from '../states/notifications.state';

const reducer = createReducer(
    defaultNotificationsState,
    on(showNotification, (state, { message, alertType, component }) => ({
        ...state,
        message,
        type: alertType,
        component: component,
    })),
    on(showMessageNotification, (state, { message, alertType, component }) => ({
        ...state,
        message,
        type: alertType,
        component: component,
    })),
    on(hideNotification, (state) => ({
        ...state,
        message: null,
        type: null,
    }))
);

export function notificationsReducer(
    state: NotificationsState,
    action: Action
) {
    return reducer(state, action);
}
