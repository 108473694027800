export class directoryUserModel {
    id?: string;
    name: string;
    email: string;
    phone: string;
    responsability: string;
    location: string;
    department: string;
    position: string;
    status: boolean;
    constructor(item: any, id = 10) {
        this.id = item.id || id;
        this.name = item.name;
        this.email = item.email;
        this.phone = item.phone;
        this.responsability = item.responsability;
        this.location = item.location;
        this.department = item.department;
        this.position = item.position;
        this.status = item.status;
    }
}
