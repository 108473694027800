import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'publishingDate',
})
export class PublishingDatePipe implements PipeTransform {
    private days_posted: number = 0;
    transform(value: any, ...args: unknown[]): unknown {
        var fechaIni = new Date(value);

        var fechaFin = new Date();

        let day_diff = <any>fechaFin - <any>fechaIni;

        this.days_posted = Math.floor(day_diff / (1000 * 60 * 60 * 24));

        return `hace ${this.days_posted} dias`;
    }
}
