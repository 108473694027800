import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { blogModel } from '../../models/blog/blog.model';
import { BlogService } from '../../services/blog/blog.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

import {
    createBlog,
    deleteBlog,
    getBlogs,
    searchBlogByTitle,
    setBlogs,
    setSelectedBlog,
    updateBlog,
} from '../actions/blog.actions';
import { navigate } from '../actions/navigation.actions';
import { showNotification } from '../actions/notifications.actions';
import {  selectBlogState } from '../selectors/blog.selectors';

@Injectable()
export class BlogEffects {
    constructor(
        private actions$: Actions,
        private blogService: BlogService,
        private store: Store,
        private localStorage: LocalStorageService
    ) {}

    public persistBlogState$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setBlogs),
                switchMap(() => this.store.select(selectBlogState)),
                map((state: any) => this.localStorage.setItem('BLOG', state))
            );
        },
        {
            dispatch: false,
        }
    );

    public getBlogs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getBlogs),
            switchMap(() => this.blogService.getBlogs()),
            map((Blogs: blogModel[]) => setBlogs({ Blogs }))
        );
    });
    public getBlogByTitle$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(searchBlogByTitle),
            switchMap((title) => this.blogService.getBlogByTitle(title.title)),
            map((blog: blogModel) => setSelectedBlog({ selectedBlog : blog }))
        );
    });
    public createBlog$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createBlog),
            switchMap(({ selectedBlog }) =>
                from(this.blogService.createBlog(selectedBlog))
            ),
            filter((u) => !!u),
            switchMap(() => [
                navigate({ url: ['main', 'blog', 'list'] }),
                showNotification({
                    message: 'Nuevo blog Creado con exito ',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    public updateBlog$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateBlog),
            switchMap(({ selectedBlog }) =>
                from(this.blogService.updateBlog(selectedBlog))
            ),
            filter((c) => c === true),
            switchMap(() => [
                showNotification({
                    message: '¡Operación exitosa!',
                    alertType: 'success',
                    component: 'alert',
                }),
                getBlogs(),
            ])
        );
    });
    public deleteBlog$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteBlog),
            switchMap(({ selectedBlog }) =>
                from(this.blogService.deleteBlog(selectedBlog))
            ),
            filter((c) => c === true),
            switchMap(() => [
                showNotification({
                    message: '¡Operación exitosa!',
                    alertType: 'success',
                    component: 'alert',
                }),
                getBlogs(),
            ])
        );
    });

    // public getCategoryCourses$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(getCategoryCourses),
    //         switchMap(({ categoryId }) =>
    //             this.trainingServ.getCategoryCourses(categoryId)
    //         ),
    //         map((categoryCourses) => setCategoryCourses({ categoryCourses }))
    //     );
    // });
}
