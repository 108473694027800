import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertComponent } from './components/alert/alert.component';
import { DropdownInputComponent } from './components/dropdown-input/dropdown-input.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { MessageComponent } from './components/message/message.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { TabLayoutComponent } from './components/tab-layout/tab-layout.component';
import { NoUserInputDirective } from './directives/no-user-input/no-user-input.directive';
 import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';


import { ImageUploadComponent } from './components/image-upload/image-upload.component';
 import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LimitTextPipe } from '../core/pipes/blog/limit-text.pipe';
import { PublishingDatePipe } from '../core/pipes/blog/publishing-date.pipe';
 import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
 import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { DropdownCheckComponent } from './components/dropdown-check/dropdown-check.component';
import { FooterComponent } from './components/footer/footer.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ClasificationsPipe } from '../core/pipes/clasification/clasification.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
 import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
 import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { KeysvaluesPipe } from '../core/pipes/selectors/keysvalues.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SortPipe } from '../core/pipes/selectors/sort.pipe';
import { Translatev2Pipe } from '../core/pipes/translate/translatev2.pipe';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { PdfPriceListComponent } from './components/pdf-price-list/pdf-price-list.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SafePipe } from '../core/pipes/safe/safe.pipe';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';

@NgModule({
    declarations: [
        DropdownInputComponent,
        PasswordInputComponent,
        AlertComponent,
        NoUserInputDirective,
        LoadingBarComponent,
        NavbarComponent,
        NavMenuComponent,
        SearchInputComponent,
        DropdownMenuComponent,
        ImageSliderComponent,
        TabLayoutComponent,
        SidePanelComponent,
        MessageComponent,
        ImageUploadComponent,
        FooterComponent,
        // pipes
        LimitTextPipe,
        ClasificationsPipe,
        PublishingDatePipe,
        DropdownCheckComponent,
        FileUploadComponent,
        KeysvaluesPipe,
        SortPipe,
        SafePipe,
        Translatev2Pipe,
        ConfirmComponent,
        PdfPriceListComponent,
        SkeletonComponent
        // components
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
         MatMenuModule,
         MatIconModule,
         MatButtonModule,
         MatDialogModule,
         MatSelectModule,
        MatExpansionModule,
        CKEditorModule,
         MatFormFieldModule,
         MatInputModule,
         MatButtonModule,
        MatIconModule,
         MatMenuModule,
        NgxDatatableModule,
        TranslateModule
    ],
    exports: [
        CKEditorModule,
        NgxDatatableModule,
         MatInputModule,
        DropdownInputComponent,
        PasswordInputComponent,
        AlertComponent,
        NoUserInputDirective,
        LoadingBarComponent,
        NavbarComponent,
        NavMenuComponent,
        SearchInputComponent,
        DropdownMenuComponent,
        ImageSliderComponent,
        TabLayoutComponent,
        ReactiveFormsModule,
        SidePanelComponent,
        MessageComponent,
        ImageUploadComponent,
         MatMenuModule,
        MatIconModule,
         MatButtonModule,
        MatExpansionModule,
// pipes
        LimitTextPipe,
        ClasificationsPipe,
        PublishingDatePipe,
        KeysvaluesPipe,
        SortPipe,
        Translatev2Pipe,
        SafePipe,
        
         MatDialogModule,
        DropdownCheckComponent,
         MatSelectModule,
        FooterComponent,
        FileUploadComponent,
        // traslate 
        TranslateModule,
        // pdExport
        PdfPriceListComponent,
        SkeletonComponent
    ],
})
export class SharedModule {}
