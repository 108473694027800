<span class="input" (click)="onInputClick()" [ngClass]="{ opened: toggle }">
    <input
        type="text"
        #input
        [placeholder]="placeholder"
        [ngModel]="value?.label"
        autocomplete="off"
        autocomplete="disabled"
    />
    <i class="icon-arrow-down input__icon"></i>
</span>
<div class="dropdown__options" *ngIf="toggle">
    <p
        *ngFor="let option of options"
        class="dropdown__option"
        (click)="onOptionSelected(option.value)"
    >
        {{ option.label }}
    </p>
    <p
        *ngIf="options.length === 0"
        class="dropdown__option--no-items"
        disabled="true"
    >
        No hay coincidencias
    </p>
</div>
