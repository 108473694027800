import { createAction, props } from '@ngrx/store';
import { IRegistryPersonalInfo } from '../../models/auth/personal_info.model';
import { roles } from '../../models/auth/roles.model';
import { SieUser } from '../../models/auth/user.model';

export const loginUser = createAction(
    '[Auth] Login user',
    props<{ email: string; pass: string }>()
);
export const setUserData = createAction(
    '[Auth] Set user data',
    props<{ user_data: SieUser }>()
);

export const setRegistryPersonalInfo = createAction(
    '[Auth] Set personal info',
    props<{ info: IRegistryPersonalInfo }>()
);

export const setRolePersonalInfo = createAction(
    '[Auth] Set role personal info',
    props<{ role_info: roles }>()
);

export const sendEmailVerification = createAction(
    '[Auth] Send email verification'
);
export const signUpUser = createAction(
    '[Auth] Sign up',
    props<{ info: IRegistryPersonalInfo }>()
);

export const signOutUser = createAction('[Auth] Sign out');

export const sendRecoveryEmail = createAction(
    '[Auth] Send recovery email',
    props<{ email: string }>()
);

export const sentEmail = createAction('[Auth] Sent email');

export const throwAuthError = createAction(
    '[Auth] Throw error',
    props<{ code: string }>()
);
