<i
    class="icon-arrow-left btn-icon"
    *ngIf="prevRoute$ | async as route"
    (click)="onBackClick(route)"
></i>
<h2>{{ title$ | async | translatev2 | async }}</h2>

<i class="icon-sie-logo"> </i>

<i class="icon icon__lang" [matMenuTriggerFor]="menuLang"> 
    <img [src]="iconLang" alt="languaje" width="16" height="12">{{lang}}
</i>   

<i
    *ngIf="hiddeControls"
    class="icon-notification btn-icon"
    (click)="openNotifications()"
    [matMenuTriggerFor]="menunotify"
></i>
<mat-menu #menuLang="matMenu" >
    <ul class="lang__list">
        <li class="lang__list__item " [ngClass]="{'lang__list__item--active': lang == 'es'}"  (click)="ChangeLanguage('es')">
            <img src="/assets/images/flag-MX.svg" alt="Fla MX" srcset="" width="16" height="12">
            Español
        </li>
        <li class="lang__list__item  " (click)="ChangeLanguage('en')" [ngClass]="{'lang__list__item--active': lang == 'en'}">
            <img src="/assets/images/flag-US.svg" alt="Flag Us" srcset="" width="16" height="12">
            Ingles
        </li>
    </ul>
</mat-menu>
<mat-menu #menunotify="matMenu">
    <div class="class-notification" *ngFor="let item of notifications">
        <a [href]="item.data.url" target="_blank">
            <div class="body">
                <img src="/assets/images/Logo_EP Hub.png" alt="Logo Ep-Hub" />
                <div class="text_info">
                    <p class="title">{{ item.data.title }}</p>
                    <p class="description">
                        {{ item.data.description }}
                    </p>
                </div>
            </div>
        </a>
    </div>
</mat-menu>

<i class="icon-logout btn-icon" *ngIf="hiddeControls" (click)="singOut()"></i>
<i class="icon-login btn-icon" *ngIf="!hiddeControls" (click)="login()"></i>
<i
    class="icon-burger btn-icon"
    [ngClass]="{ 'icon-burger': !menuOpen, 'icon-close': menuOpen }"
    (click)="onBurguerClick()"
></i>
