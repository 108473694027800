import { directoryUserModel } from '../../models/directory/directory-users.model';

export interface DirectoryState {
    usersDirectory: directoryUserModel[];
    selectedUserDirectory: directoryUserModel;
    departmentsList: DropdownOption[];
    positionsList: DropdownOption[];
    loading: boolean;
}

export const defaultDirectoryState: DirectoryState = {
    usersDirectory: [],
    selectedUserDirectory: null,
    departmentsList: [],
    positionsList: [],
    loading: false,
};
