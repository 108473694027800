<span class="input" [ngClass]="{ 'show-pass': showPass }">
    <input
        [ngModel]="value"
        (ngModelChange)="onInputChange($event)"
        (blur)="onTouched()"
        [disabled]="disabled"
        [type]="showPass ? 'text' : 'password'"
    />
    <i class="icon-eye input__icon" (click)="onShowPassClick()"></i>
</span>
