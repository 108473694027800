import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AngularFireStorage,
    AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
    selector: 'si-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit, OnDestroy {
    @Input() file: File;
    @Output() fileUpload = new EventEmitter<string>();
    @Output() deleteFile = new EventEmitter<string>();

    public task: AngularFireUploadTask;

    public percentage: Observable<number>;
    public snapshot: Observable<any>;
    public downloadURL: string;

    public removed: boolean = false;
    constructor(private storage: AngularFireStorage) {}

    ngOnInit() {
        this.startUpload();
    }

    startUpload() {
        const path = `test/${Date.now()}_${this.file.name}`;

        const ref = this.storage.ref(path);

        this.task = this.storage.upload(path, this.file);

        this.percentage = this.task.percentageChanges();

        this.snapshot = this.task.snapshotChanges().pipe(
            finalize(async () => {
                this.downloadURL = await ref.getDownloadURL().toPromise();
                this.fileUpload.emit(this.downloadURL);
            })
        );
    }

    isActive(snapshot: any) {
        return (
            snapshot.state === 'running' &&
            snapshot.bytesTransferred < snapshot.totalBytes
        );
    }
    async delete() {
        let pictureRef = this.storage.refFromURL(this.downloadURL);
        pictureRef
            .delete()
            .toPromise()
            .then(() => {
                this.removed = true;
                this.deleteFile.emit(this.downloadURL);
            })
            .catch((err) => {
                alert('intenta nuevamente' + err);
            });
    }
    eliminarComponente() {
        this.ngOnDestroy();
    }
    ngOnDestroy() {}
}
