export class SieUser {
    public displayName: string;
    public email: string;
    public emailVerified: boolean;
    public metadata: {
        creationTime?: string;
        lastSignInTime?: string;
    };
    public photoURL: string;
    public phoneNumber: string;
    public refreshToken: string;
    public uid: string;

    constructor(firebase_resp: any) {
        if (firebase_resp) {
            this.displayName = firebase_resp.displayName ?? null;
            this.email = firebase_resp.email ?? null;
            this.emailVerified = firebase_resp.emailVerified ?? null;
            this.metadata = firebase_resp.metadata
                ? { ...firebase_resp.metadata }
                : {};
            this.photoURL = firebase_resp.photoURL ?? null;
            this.phoneNumber = firebase_resp.phoneNumber ?? null;
            this.refreshToken = firebase_resp.refreshToken ?? null;
            this.uid = firebase_resp.uid ?? null;
        }
    }
}
