export class DistributorModel {
    id?: string;
    name: string;
    type: string;
    street: string;
    block: string;
    nClient?: string;
    city: string;
    ZipCode?: string;
    state: string;
    email: string;
    phone: string;
    webpage?: string;
    schedule: string;
    web?: string;
    ecommerce?: string;
    lat?: number;
    long?: number;
    distance?: number;

    constructor(id = '', item?: any) {
        if (item) {
            this.id = id;
            this.name = item.name;
            this.type = item.type;
            this.email = item.email;
            this.phone = item.phone;
            this.street = item.street;
            this.block = item.block;
            this.city = item.city;
            this.state = item.state;
            this.schedule = item.schedule;
            this.lat = item.lat;
            this.long = item.long;
            this.web = item.web || 'N/A';
            this.ecommerce = item.ecommerce || 'N/A';
        }
    }
}
