import { Action, createReducer, on } from '@ngrx/store';
import {
    cleanSelector,
    deleteSelector,
    getProductsSelector,
    getSelectors,
    getSpectsSelectors,
    newSelector,
    saveSpectsSelector,
    setFilteresOptions,
    setProduct,
    setProductsFiltered,
    setProductsSelector,
    setSelector,
    setSelectors,
    setSpectSelectors,
    uploadProductsSelector,
} from '../actions/selectors.action';

import {
    SelectorsState,
    defaultSelectorsState,
} from '../states/selectors.state';

const reducer = createReducer(
    defaultSelectorsState,
    on(getSelectors, (state: SelectorsState) => ({
        ...state,
        loading: true,
    })),
    on(setSelectors, (state: SelectorsState, { selectorsList }) => ({
        ...state,
        selectorsList,
        loading: true,
    })),
    on(setSelector, (state: SelectorsState, { selectorSelected }) => ({
        ...state,
        selectorSelected,
        loading: true,
    })),

    on(newSelector, (state: SelectorsState, { selectorSelected }) => ({
        ...state,
        selectorSelected,
        loading: true,
    })),

    on(setProduct, (state: SelectorsState, { productSelected }) => ({
        ...state,
        productSelected,
        loading: false,
    })),
    on(getProductsSelector, (state: SelectorsState, { selectorSelected }) => ({
        ...state,
        selectorSelected,
        loading: true,
    })),

    on(deleteSelector, (state: SelectorsState, { selectorSelected }) => ({
        ...state,
        selectorSelected,
        loading: true,
    })),

    on(setProductsSelector, (state: SelectorsState, { productsList }) => ({
        ...state,
        productsList,
        loading: true,
    })),

    on(uploadProductsSelector, (state: SelectorsState, { productsList }) => ({
        ...state,
        productsList,
        loading: true,
    })),

    on(setFilteresOptions, (state: SelectorsState, { filteredOptions }) => ({
        ...state,
        filteredOptions,
        loading: true,
    })),
    on(saveSpectsSelector, (state: SelectorsState, { filteredOptions }) => ({
        ...state,
        filteredOptions,
        loading: true,
    })),
    on(setProductsFiltered, (state: SelectorsState, { filteredProducts }) => ({
        ...state,
        filteredProducts,
        loading: true,
    })),
    on(getSpectsSelectors, (state: SelectorsState, { selectorSelected }) => ({
        ...state,
        selectorSelected,
        loading: true,
    })),

    on(setSpectSelectors, (state: SelectorsState, { filteredOptions }) => ({
        ...state,
        filteredOptions,
        loading: true,
    })),
    on(setProductsSelector, (state: SelectorsState, { productsList }) => ({
        ...state,
        productsList,
        loading: true,
    })),
    on(cleanSelector, (state: SelectorsState) => ({
        ...state,
        productsList: null,
        filteredOptions: null,
        filteredProducts: null,
        loading: true,
    }))
);

export function selectorsReduce(state: SelectorsState, action: Action) {
    return reducer(state, action);
}
