import {
    Component,
    EventEmitter,
    forwardRef,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'si-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PasswordInputComponent),
            multi: true,
        },
    ],
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor {
    public showPass = false;
    public value = '';
    public onChange = (_: any) => {};
    public onTouched = () => {};
    public disabled = false;
    @Output() change = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}
    writeValue(value: string) {
        this.value = value;
    }
    registerOnChange(func: any) {
        this.onChange = func;
    }
    registerOnTouched(func: any) {
        this.onTouched = func;
    }
    setDisabledState(state: boolean) {
        this.disabled = state;
    }
    public onShowPassClick() {
        this.showPass = !this.showPass;
    }
    public onInputChange(event: any) {
        this.onTouched();
        this.onChange(event);
    }
}
