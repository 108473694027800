import { Products } from './catalogues-products.model';

export class ClasificationsProducts {
    id: string;
    type: string;
    name: string;
    code: string;
    image: string;
    description: string;
    tags: string;
    // Products: Partial<Products>[];
    // ProductsCount?: Number;

    constructor(item: any) {
        this.id = item.id;
        this.image = item.image || '';
        this.name = item.name;
        this.type = item.type;
        this.description = item.description;
        this.tags = item.tags || '';
        this.code = item.code || '';
        // this.Products = item.Products;
        // this.ProductsCount = this.Products.length;
    }

    // constructor(id: any, item: any, products: Products[] = [], type: any) {
    //     this.id = id;
    //     this.name = item.name;
    //     this.type = type;
    //     this.description = item.description;
    //     this.Products = products;
    //     this.ProductsCount = this.Products.length;
    // }
}
