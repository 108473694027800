import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'main',
        loadChildren: () =>
            import('./modules/main/main.module').then((m) => m.MainModule),
    },
    {
        path: '',
        loadChildren: () =>
            import('./modules/landing/landing.module').then(
                (m) => m.LandingModule
            ),
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
    },
    {
        path: '*',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false ,useHash: false })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
