import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Component({
    selector: 'si-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true,
        },
    ],
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
    public value: string;
    public toggle = false;
    public disabled = false;
    public loading = false;

    public onChange = (_: any) => {};
    public onTouched = () => {};
    @Input() folder  : string 
    @ViewChild('fileChosse') fileChosse: ElementRef<HTMLButtonElement>;

    constructor(private storageService: StorageService) {}
    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
    }

    @Output() change = new EventEmitter();

    changeFile($event: any) {
        let file = $event.target.files[0];
        if (file) {
            this.loading = true;
            this.storageService
                .pushFileToStorage(file, ( this.folder || `/products`))
                .subscribe((publicURL) => {
                    this.onChange(publicURL);
                    this.value = publicURL;
                    this.loading = false;
                });
        }
    }

    writeValue(value: string): void {
        this.value = value;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    OpenChose() {
        // console.log("select file");
        
        if (!this.value) {
            this.fileChosse.nativeElement.click();
        } else {
            let confirm = window.confirm('¿Deseas cambiar el archivo?');
            if (confirm) {
                this.fileChosse.nativeElement.click();
            }
        }
    }
}
