import { createAction, props } from '@ngrx/store';

export const getSelectors = createAction('[Selectors] Get Selectors list');

export const setSelectors = createAction(
    '[Selectors] Set Selectors list',
    props<{ selectorsList: DropdownOption[] }>()
);

export const getSpectsSelectors = createAction(
    '[Selectors] Get Spects Selector',
    props<{ selectorSelected: any }>()
);

export const setSpectSelectors = createAction(
    '[Selectors] Set Spects Selectors',
    props<{ filteredOptions: any[] }>()
);

export const newSelector = createAction(
    '[Selectors] New Selector',
    props<{ selectorSelected: any }>()
);

export const setSelector = createAction(
    '[Selectors] Set Selector',
    props<{ selectorSelected: any }>()
);

export const setProduct = createAction(
    '[Selectors] Set Product',
    props<{ productSelected: any }>()
);

export const getProductsSelector = createAction(
    '[Selectors] Get Products selector',
    props<{ selectorSelected: string }>()
);

export const deleteSelector = createAction(
    '[Selectors] Delete Selector',
    props<{ selectorSelected: string }>()
);

export const cleanSelector = createAction('[Selectors] Clean Selector');

export const setProductsSelector = createAction(
    '[Selectors] Set Products selector',
    props<{ productsList: any[] }>()
);

export const setProductsFiltered = createAction(
    '[Selectors] Set Products filteres',
    props<{ filteredProducts: any[] }>()
);

export const setFilteresOptions = createAction(
    '[Selectors] Set Filteres Options',
    props<{ filteredOptions: any[] }>()
);
export const saveSpectsSelector = createAction(
    '[Selectors] Save Filteres Options',
    props<{ filteredOptions: any[] }>()
);

export const uploadProductsSelector = createAction(
    '[Selectors] Set Upload Products selector',
    props<{ productsList: any[] }>()
);
