export interface GeolocationState {
    countries: DropdownOption[];
    country_states: DropdownOption[];
    loading: boolean;
}

export const defaultGeolocationState: GeolocationState = {
    countries: [],
    country_states: [],
    loading: false,
};
