import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs/operators';

@Component({
    selector: 'si-dropdown-check',
    templateUrl: './dropdown-check.component.html',
    styleUrls: ['./dropdown-check.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownCheckComponent),
            multi: true,
        },
    ],
})
export class DropdownCheckComponent
    implements
        OnInit,
        ControlValueAccessor,
        AfterViewInit,
        OnDestroy,
        OnChanges
{
    @HostBinding('class.dropdown-input') inputClass = true;
    private destroy$ = new Subject();
    public toggle = false;
    public onChange = (_: any) => {};
    public onTouched = () => {};
    public disabled = false;
    public value: Array<DropdownOption> = [];
    private allOptions!: DropdownOption[];
    @Input() placeholder = '...';
    @Input() filterable = true;
    @Input() options: DropdownOption[] = [];
    @Output() change = new EventEmitter();
    @ViewChild('input') input!: ElementRef;

    constructor() {
        fromEvent(document, 'click')
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: any) => {
                const isOption =
                    event.path.filter((p: any) =>
                        String(p.className).includes('dropdown__option')
                    ).length > 0;
                const isInput =
                    event.path.filter((p: any) =>
                        String(p.className).includes('dropdown-input')
                    ).length > 0;
                if (!isOption && !isInput && this.toggle) {
                    this.toggle = false;
                    this.onTouched();
                }
                if (isOption) {
                    this.toggle = false;
                }
            });
    }

    ngOnInit(): void {
        this.allOptions = this.options;
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && !changes.options.isFirstChange()) {
            this.allOptions = this.options;
        }
    }
    ngOnDestroy(): void {
        this.destroy$.next(true);
    }
    ngAfterViewInit(): void {
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                map((e: any) => e.target.value)
            )
            .subscribe((v: string) => {
                this.options = v
                    ? this.allOptions.filter((o) =>
                          o.label.toLowerCase().includes(v.toLowerCase())
                      )
                    : this.allOptions;
            });
    }
    writeValue(value: string) {
        // this.value += this.allOptions.find((o) => o.value === value).value;
        this.value.push(this.allOptions.find((o) => o.value === value));
    }
    registerOnChange(func: any) {
        this.onChange = func;
    }
    registerOnTouched(func: any) {
        this.onTouched = func;
    }
    setDisabledState(state: boolean) {
        this.disabled = state;
    }
    public onInputClick() {
        this.toggle = !this.toggle;
        this.input.nativeElement.focus();
        if (!this.toggle) {
            this.onTouched();
        }
    }
    public onOptionSelected(value: any) {
        this.value.push(this.allOptions.find((o) => o.value === value));
        let index = this.allOptions.findIndex((o) => o.value === value);
        // this.allOptions[index].selected = !this.allOptions[index].selected;
        this.options = this.allOptions;
        // this.onTouched();
        // this.onChange(value);
    }
}
