import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_URLS } from '../../constants/api-urls';

@Injectable({
    providedIn: 'root',
})
export class GeolocationService {
    constructor(private http: HttpClient) {}

    public getCountriesLookup(): Observable<DropdownOption[]> {
        return this.http
            .get(`${API_URLS.BASE_URL}${API_URLS.GEOLOCATION.COUNTRIES}`)
            .pipe(
                map((r: any) =>
                    r.map((c: any) => ({
                        label: c.name,
                        value: c.id,
                    }))
                )
            );
    }

    public getCountryStates(id: string): Observable<DropdownOption[]> {
        return this.http
            .get(
                `${API_URLS.BASE_URL}${API_URLS.GEOLOCATION.COUNTRY_STATES}/${id}`
            )
            .pipe(
                map((r: any) =>
                    r.map((c: any) => ({
                        label: c.name,
                        value: c.id,
                    }))
                )
            );
    }
}
