import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AuthService } from '../../services/auth/auth.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import {
    loginUser,
    sendEmailVerification,
    sendRecoveryEmail,
    sentEmail,
    setRegistryPersonalInfo,
    setUserData,
    signOutUser,
    signUpUser,
} from '../actions/auth.actions';
import { navigate } from '../actions/navigation.actions';
import { showNotification } from '../actions/notifications.actions';
import { selectAuthState } from '../selectors/auth.selectors';
import { AuthState } from '../states/auth.state';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private store: Store,
        private localStorage: LocalStorageService
    ) {}
    public persistAuthState$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setUserData, setRegistryPersonalInfo),
                switchMap(() => this.store.select(selectAuthState)),
                filter((s) => !!s),
                map((state: AuthState) =>
                    this.localStorage.setItem('AUTH', state)
                )
            );
        },
        {
            dispatch: false,
        }
    );

    public loginUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginUser),
            switchMap(({ email, pass }) =>
                from(this.authService.loginUser(email, pass))
            ),
            filter((u) => !!u),
            switchMap((user_data) => [
                setUserData({ user_data }),
                navigate({ url: [''] }),
            ])
        );
    });

    public signUpUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signUpUser),
            switchMap(({ info }) => from(this.authService.signUpUser(info))),
            filter((u) => !!u),
            switchMap((user_data) => [
                setUserData({ user_data }),
                navigate({ url: ['auth', 'registry', 'confirmation'] }),
                showNotification({
                    message: 'notification.userCreated',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });

    public signOutUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signOutUser),
            switchMap(() => from(this.authService.signOutuser())),
            filter((u) => u == true),
            switchMap(() => [
                showNotification({
                    message:'¡Vuelva pronto!',
                    alertType: 'success',
                    component: 'alert',
                }),
                setUserData({ user_data: null }),
                navigate({ url: [''] }),
            ])
        );
    });

    public recoverPass$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendRecoveryEmail),
            switchMap(({ email }) =>
                from(this.authService.sendPasswordResetEmail(email))
            ),
            filter((sent) => sent === true),
            switchMap(() => [
                sentEmail(),
                showNotification({
                    message:'notifications.sendLinkForgot',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });

    public sendVerficationEmail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendEmailVerification),
            switchMap(() => from(this.authService.sendVerificationEmail())),
            filter((sent) => sent === true),
            switchMap(() => [
                sentEmail(),
                showNotification({
                    message: 'notification.sendEmailVerification',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
}
