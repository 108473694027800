import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ClasificationsProducts } from '../../models/catalogues/catalogues-clasification.model';
import { Products } from '../../models/catalogues/catalogues-products.model';
import { showNotification } from '../../store/actions/notifications.actions';

@Injectable({
    providedIn: 'root',
})
export class CataloguesService {
    constructor(public db: AngularFirestore, private store: Store) {}

    getClasificationsProducts(): Observable<ClasificationsProducts[]> {
        const observable = from(this.getAllAreas());
        return observable;
    }
    getProducts(): Observable<Products[]> {
        return this.db
            .collection('products')
            .get()
            .pipe(
                map((res) =>
                    res.docs.map((doc) => new Products(doc.id, doc.data()))
                )
            );
    }

    async createClasification(category: ClasificationsProducts) {
        // console.log(category);
        switch (category.type) {
            case 'area':
                return this.saveData('aplication_areas', category)
                    .then((c) => true)
                    .catch((err) => {
                        this.store.dispatch(
                            showNotification({
                                message: 'Ocurrio un error al eliminar',
                                alertType: 'error',
                                component: 'alert',
                            })
                        );
                    });
                break;
            case 'fam':
                return this.saveData('families', category)
                    .then((c) => true)
                    .catch((err) => {
                        this.store.dispatch(
                            showNotification({
                                message: 'Ocurrio un error al eliminar',
                                alertType: 'error',
                                component: 'alert',
                            })
                        );
                    });
                break;
            case 'norm':
                return this.saveData('normatives', category)
                    .then((c) => true)
                    .catch((err) => {
                        this.store.dispatch(
                            showNotification({
                                message: 'Ocurrio un error al eliminar',
                                alertType: 'error',
                                component: 'alert',
                            })
                        );
                    });
                break;
            case 'type':
                return this.saveData('types', category)
                    .then((c) => true)
                    .catch((err) => {
                        this.store.dispatch(
                            showNotification({
                                message: 'Ocurrio un error al eliminar',
                                alertType: 'error',
                                component: 'alert',
                            })
                        );
                    });
                break;
        }
    }

    deleteClasification(clasification: ClasificationsProducts): any {
        // console.log(clasification);
        switch (clasification.type) {
            case 'area':
                return this.db
                    .collection('aplication_areas')
                    .doc(clasification.id)
                    .delete()
                    .then((c) => true)
                    .catch((err) => {
                        return false;
                    });
                break;
            case 'fam':
                return this.db
                    .collection('families')
                    .doc(clasification.id)
                    .delete()
                    .then((c) => true)
                    .catch((err) => {
                        return false;
                    });
                break;
            case 'norm':
                return this.db
                    .collection('normatives')
                    .doc(clasification.id)
                    .delete()
                    .then((c) => true)
                    .catch((err) => {
                        return false;
                    });
                break;
            case 'type':
                return this.db
                    .collection('types')
                    .doc(clasification.id)
                    .delete()
                    .then((c) => true)
                    .catch((err) => {
                        return false;
                    });
                break;
        }
    }

    async saveData(
        collection: string,
        category: ClasificationsProducts
    ): Promise<boolean> {
        if (category.id === '') {
            return this.db
                .collection(collection)
                .add(category)
                .then((c) => true)
                .catch((err) => {
                    return false;
                });
        } else {
            return this.db
                .collection(collection)
                .doc(category.id)
                .set(category)
                .then((c) => true)
                .catch((err) => {
                    return false;
                });
        }
    }

    async getAllAreas() {
        let all: ClasificationsProducts[] = [];
        await this.db
            .collection('aplication_areas')
            .get()
            .toPromise()
            .then((querySnapshot) => {
                querySnapshot.forEach(async (doc: any) => {
                    all.push(
                        new ClasificationsProducts({
                            id: doc.id,
                            type: 'area',
                            image: doc.data().image,
                            code : doc.data().code,
                            name: doc.data().name,
                            description: doc.data().description,
                            tags: doc.data().tags,
                        })
                    );
                });
            });
        await this.db
            .collection('families')
            .get()
            .toPromise()
            .then((querySnapshot) => {
                querySnapshot.forEach(async (doc: any) => {
                    all.push(
                        new ClasificationsProducts({
                            id: doc.id,
                            image: doc.data().image,
                            type: 'fam',
                            code : doc.data().code,
                            name: doc.data().name,
                            description: doc.data().description,
                            tags: doc.data().tags,
                        })
                    );
                });
            });
        await this.db
            .collection('normatives')
            .get()
            .toPromise()
            .then((querySnapshot) => {
                querySnapshot.forEach(async (doc: any) => {
                    all.push(
                        new ClasificationsProducts({
                            id: doc.id,
                            type: 'norm',
                            image: doc.data().image,
                            code : doc.data().code,
                            name: doc.data().name,
                            description: doc.data().description,
                            tags: doc.data().tags,
                        })
                    );
                });
            });
        await this.db
            .collection('types')
            .get()
            .toPromise()
            .then((querySnapshot) => {
                querySnapshot.forEach(async (doc: any) => {
                    all.push(
                        new ClasificationsProducts({
                            id: doc.id,
                            type: 'type',
                            image: doc.data().image,
                            name: doc.data().name,
                            code : doc.data().code,
                            description: doc.data().description,
                            tags: doc.data().tags,
                        })
                    );
                });
            });
        return all;
    }
    
    async deleteProduct(product: Products) {
        let refUpdate = this.db.collection('products').doc(product.id);

        return refUpdate
            .delete()
            .then((c) => {
                return true;
            })
            .catch((e) => {
                return false;
            });
    }
    async updateProduct(product : any ) {
        let refUpdate = this.db.collection('products').doc(product.id);

        let newData = JSON.parse(JSON.stringify(product));

        return refUpdate
            .set(newData,{ merge : true})
            .then((c) => {
                return true;
            })
            .catch((e) => {
                return false;
            });
    }
    async createProduct(product : any) {
        let refUpdate = this.db.collection('products');

        let newData = JSON.parse(JSON.stringify(product));

        return refUpdate
            .add(newData)
            .then((c) => {
                return true;
            })
            .catch((e) => {
                return false;
            });
    }

    getProductsWhithModel(): Observable<Products[]> {
        return this.db
            .collection('products',fn=> fn.where("Modelo3D", "!=", ""))
            .get()
            .pipe(
                map((res) =>
                    res.docs.map((doc) => new Products(doc.id, doc.data()))
                )
            );
    }

}
