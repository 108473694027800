import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import newsModel from '../../models/news/news.model';
import { NewsService } from '../../services/news/news.service';
import { getNews, setNews } from '../actions/news.actions';
import { showNotification } from '../actions/notifications.actions';

@Injectable()
export class NewsEffects {
    constructor(private actions$: Actions, private newsService: NewsService) {}
    newsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getNews),
            switchMap(() => this.newsService.getNewList()),
            map((newsList) => setNews({ newsList }))
        );
    });
}
