import { createAction, props } from '@ngrx/store';
import { directoryUserModel } from '../../models/directory/directory-users.model';

export const setUserSelectedDirectory = createAction(
    '[Directory] Set selected directory',
    props<{ selectedUserDirectory: directoryUserModel }>()
);

export const createNewUserDirectory = createAction(
    '[Directory] Create user directory',
    props<{ selectedUserDirectory: directoryUserModel }>()
);
export const updateUserDirectory = createAction(
    '[Directory] Update user directory ',
    props<{ selectedUserDirectory: directoryUserModel }>()
);
export const deleteUserDirectory = createAction(
    '[Directory] Delete user directory ',
    props<{ selectedUserDirectory: directoryUserModel }>()
);

export const getDirectory = createAction('[Directory] Get directory');
export const setDirectory = createAction(
    '[Directory] Set Directory',
    props<{ usersDirectory: directoryUserModel[] }>()
);
// export const getPositions = createAction('[Directory] Get positions list');
// export const setPositions = createAction(
//     '[Directory] Set positions list',
//     props<{ positionsList: DropdownOption[] }>()
// );
// export const getDepartments = createAction('[Directory] Get departments list');
// export const setDepartments = createAction(
//     '[Directory] Set departments',
//     props<{ departmentsList: DropdownOption[] }>()
// );
export const removeSelectedDirecory = createAction(
    '[Directory] Remove selected directory'
);
// export const getDirectory = createAction('[Directory] Get Directory');
