import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { PruebasService } from '../../services/pruebas/pruebas.service';
import { cleanProduct } from '../actions/catalogues.actions';
import { showNotification } from '../actions/notifications.actions';

import {
    deleteSelector,
    getProductsSelector,
    getSelectors,
    getSpectsSelectors,
    newSelector,
    saveSpectsSelector,
    setProductsSelector,
    setSelectors,
    setSpectSelectors,
    uploadProductsSelector,
} from '../actions/selectors.action';

@Injectable()
export class SelectorsEffects {
    constructor(
        private actions$: Actions,
        private selectorsServ: PruebasService
    ) {}

    public getSelectorsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getSelectors),
            switchMap(() => this.selectorsServ.getSelectorsList()),
            map((selectorsList) => setSelectors({ selectorsList }))
        );
    });

    public getProductsSelector$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProductsSelector),
            switchMap(({ selectorSelected }) =>
                this.selectorsServ.getProductsSelector(selectorSelected)
            ),
            map((productsList) => setProductsSelector({ productsList }))
        );
    });

    public newSelector$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(newSelector),
            switchMap(({ selectorSelected }) =>
                from(this.selectorsServ.newSelector(selectorSelected))
            ),
            filter((c) => c == true),
            map(() => getSelectors())
        );
    });
    public deleteSelector$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteSelector),
            switchMap(({ selectorSelected }) =>
                from(this.selectorsServ.deleteSelector(selectorSelected))
            ),
            filter((c) => c == true),
            switchMap(() => [
                getSelectors(),
                cleanProduct(),
                showNotification({
                    message: 'Se elimino exitosamente',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    public uploadProductsSelector$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(uploadProductsSelector),
            switchMap(({ productsList }) =>
                from(this.selectorsServ.restoreSelectors(productsList))
            ),
            filter((c) => c == true),
            map(() =>
                showNotification({
                    message: 'Información almacenada con éxito',
                    alertType: 'success',
                    component: 'alert',
                })
            )
        );
    });
    public saveSpectsSelector$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(saveSpectsSelector),
            switchMap(({ filteredOptions }) =>
                from(this.selectorsServ.saveSpects(filteredOptions))
            ),
            filter((c) => c == true),
            map(() =>
                showNotification({
                    message: 'Información almacenada con éxito',
                    alertType: 'success',
                    component: 'alert',
                })
            )
        );
    });
    public getSpectsSelectors$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getSpectsSelectors),
            switchMap(({ selectorSelected }) =>
                this.selectorsServ.getSpectsSelector(selectorSelected)
            ),
            map((filteredOptions) =>
                setSpectSelectors({ filteredOptions: filteredOptions })
            )
        );
    });
}
