export const firebaseDevConfig = {
    apiKey: "AIzaSyDH79_kHFIHsbecAsD7qzT_-uWqxma-wKk",
    authDomain: "siemens-app-e35e2.firebaseapp.com",
    databaseURL: "https://siemens-app-e35e2-default-rtdb.firebaseio.com",
    projectId: "siemens-app-e35e2",
    storageBucket: "siemens-app-e35e2.appspot.com",
    messagingSenderId: "1041186894576",
    appId: "1:1041186894576:web:bfda50ae4f785859244835",
    measurementId: "G-RNR24BP249"
};
