export const API_URLS = {
    BASE_URL: 'https://us-central1-siemens-app-e35e2.cloudfunctions.net/app/',

    SDIN_URL: 'https://scmsdinapi.mxapps.siemens.cloud/',
    SDIN: {
        TOKEN: 'api/Token',
        INVENTOR: 'api/Inventory',
    },
    GEOLOCATION: {
        COUNTRIES: 'api/countries',
        COUNTRY_STATES: 'api/countries/states',
    },
    TRAINING: {
        CATEGORIES: 'api/training/categories',
        COURSES: 'api/training/courses',
    },
};

export const SDINConfig = { AppName: 'Electriclub', AppKey: '1jh59SDIN#625t' };
