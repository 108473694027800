import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CataloguesService } from '../../services/catalogues/catalogues.service';
import { DirectoryService } from '../../services/directory/directory.service';
import {
    createNewUserDirectory,
    deleteUserDirectory,
    getDirectory,
    removeSelectedDirecory,
    setDirectory,
    updateUserDirectory,
} from '../actions/directory.actions';
import { navigate } from '../actions/navigation.actions';
import { showNotification } from '../actions/notifications.actions';

@Injectable()
export class DirectoryEffects {
    constructor(
        private actions$: Actions,
        private directoryService: DirectoryService
    ) {}

    public getDirectoryUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getDirectory),
            switchMap(() => this.directoryService.getDirectorySiemens()),
            map((usersDirectory) => setDirectory({ usersDirectory }))
        );
    });
    // public getDepartments$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(getDepartments),
    //         switchMap(() => this.directoryService.getDeparmentsSiemens()),
    //         map((departmentsList) => setDepartments({ departmentsList }))
    //     );
    // });
    // public getPositions$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(getPositions),
    //         switchMap(() => this.directoryService.getPositionsSiemens()),
    //         map((positionsList) => setPositions({ positionsList }))
    //     );
    // });
    public createNewUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createNewUserDirectory),
            switchMap(({ selectedUserDirectory }) =>
                from(
                    this.directoryService.createDirectoryPeople(
                        selectedUserDirectory
                    )
                )
            ),
            filter((u) => u == true),

            switchMap(() => [
                navigate({ url: ['main', 'directory'] }),
                showNotification({
                    message: 'Usuario creado con éxito',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    public updateUserDirectory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateUserDirectory),
            switchMap(({ selectedUserDirectory }) =>
                from(
                    this.directoryService.updateUserDirectory(
                        selectedUserDirectory
                    )
                )
            ),
            filter((u) => u == true),

            switchMap(() => [
                removeSelectedDirecory(),
                navigate({ url: ['main', 'directory'] }),
                showNotification({
                    message: 'Usuario creado con éxito',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    public deleteUserDirectory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserDirectory),
            switchMap(({ selectedUserDirectory }) =>
                from(
                    this.directoryService.deleteUserDirectory(
                        selectedUserDirectory
                    )
                )
            ),
            filter((u) => u == true),

            switchMap(() => [
                removeSelectedDirecory(),
                navigate({ url: ['main', 'directory'] }),
                showNotification({
                    message: 'Usuario creado con éxito',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });

    // public getCategoryCourses$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(getCategoryCourses),
    //         switchMap(({ categoryId }) =>
    //             this.cataloguesService.getCategoryCourses(categoryId)
    //         ),
    //         map((categoryCourses) => setCategoryCourses({ categoryCourses }))
    //     );
    // });
}
