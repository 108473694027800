import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[siNoUserInput]',
})
export class NoUserInputDirective {
    @Input() filterable: boolean = true;
    @HostListener('keypress') onKeyPress() {
        return false;
    }
    constructor(private elRef: ElementRef) {}
}
