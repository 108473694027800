import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector,
} from '@ngrx/store';

import { price_listState } from '../states/price-list.state';

export const selectPriceListState: MemoizedSelector<object, price_listState> =
    createFeatureSelector<price_listState>('price_list');

export const selectPriceList = createSelector(
    selectPriceListState,
    (state: price_listState) => state.price_list
);

export const selectPriceListData = createSelector(
    selectPriceListState,
    (state: price_listState) => state.price_list_data
);

export const selectSubFamiliesData = createSelector(
    selectPriceListState,
    (state: price_listState) => state.price_list_data.subfamily
);

export const selectFamilie = createSelector(
    selectPriceListState,
    (state: price_listState) => state.familieSelected
);
export const selectSubFamilie = createSelector(
    selectPriceListState,
    (state: price_listState) => state.subFamilieSelected
);

export const selectFindValue = createSelector(
    selectPriceListState,
    (state: price_listState) => state.searchValue
);