import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { loadCountries, loadCountryStates, setCountries, setCountryStates } from '../actions/geoloc-lookups.actions';

@Injectable()
export class GeolocationEffects {
    constructor(
        private actions$: Actions,
        private geolocService: GeolocationService
    ) {}

    loadCountries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCountries),
            switchMap(() => this.geolocService.getCountriesLookup()),
            map((countries) => setCountries({ countries }))
        );
    });

    loadCountryStates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCountryStates),
            switchMap(({ id }) => this.geolocService.getCountryStates(id)),
            map((states) => setCountryStates({ states }))
        );
    });
}
