import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { blogModel } from '../../models/blog/blog.model';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { throwAuthError } from '../../store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { showNotification } from '../../store/actions/notifications.actions';
@Injectable({
    providedIn: 'root',
})
export class BlogService {
 
    constructor(private db: AngularFirestore, private store: Store) {}

    public getBlogs(): Observable<blogModel[]> {
        return this.db
            .collection('blogs')
            .get()
            .pipe(
                map((res) =>
                    res.docs.map((doc) => new blogModel(doc.id, doc.data()))
                )
            );
    }
     getBlogByTitle(title: string) :Observable<blogModel>{
        // console.log("llego"+title);
        return this.db.collection('blogs').doc(title).get().pipe(map(res=>{
            const doc = 
            { id: res.id, ...res.data() as blogModel } ;
             return doc
        }));
        // return this.db.collection('blogs', ref => ref.where('title', '==', title).limit(1))
        //   .get()
        //   .pipe(
        //     map(res => {
        //       const docs = res.docs;
        //       return docs.length > 0 ? { id: docs[0].id, ...docs[0].data() as blogModel } : null;
        //     })
        //   );
      }
    public getAllBranches() {
        return this.db.collection('branches').snapshotChanges();
    }

    async createBlog(blog: blogModel) {
        let newBlog = this.db.collection('blogs').doc();

        return newBlog
            .set(blog)
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
    async updateBlog(blog: blogModel) {
        let newData = JSON.parse(JSON.stringify(blog));
        newData.createAt = new Date(newData.createAt);
        return await this.db
            .collection('blogs')
            .doc(blog.id)
            .set(newData)
            .then((c) => true)
            .catch((err) => {
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }

    public async deleteBlog(data: any) {
        return await this.db
            .collection('blogs')
            .doc(data.id)
            .delete()
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
}
