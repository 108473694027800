<label style="text-transform: capitalize">{{ label }}</label>
<button class="btn btn--solid--primary input" (click)="onInputClick()">
    {{ selectedOption | translatev2 | async }} <i class="icon-arrow-down"></i>
</button>
<div class="dropdown__options" *ngIf="toggle">
    <p
        *ngFor="let option of options"
        class="dropdown__option"
        (click)="onOptionSelected(option.value)"
    >
        {{ option.label | translatev2 | async }}
    </p>
    <p
        *ngIf="options.length === 0"
        class="dropdown__option--no-items"
        disabled="true"
    >
       {{'shared.dropdown-menu.noCoincidence' | translate}}
    </p>
</div>
