import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DistributorModel } from '../../models/distributors/distributor.model';
import { throwAuthError } from '../../store/actions/auth.actions';
import { showNotification } from '../../store/actions/notifications.actions';

@Injectable({
    providedIn: 'root',
})
export class DistributorsService {
    constructor(private db: AngularFirestore, private store: Store) {}

    async createDistributor(selected: any) {
        return this.db
            .collection('distributors')
            .doc()
            .set({ ...selected })
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }

    async restoreDistributors(distributors: DistributorModel[]) {
        try {
            await this.db
                .collection('distributors')
                .get()
                .toPromise()
                .then((c) => {
                    c.docs.forEach(async (doc) => {
                        await doc.ref
                            .delete()
                            .then((c) => {
                                // console.log('Se elimino');
                            })
                            .catch((err) => {
                                // console.log('No se elimino', err);
                            });
                    });
                }).catch(err=>{
                    console.error(err)
                });

            distributors.forEach((c) => {
                if (c.id) {
                    this.db
                        .collection('distributors')
                        .doc(c.id)
                        .set(c, { merge: true })
                        .catch((e) => {
                            this.store.dispatch(
                                showNotification({
                                    message: 'Error interno ',
                                    alertType: 'error',
                                    component: 'alert',
                                })
                            );
                            this.store.dispatch(
                                throwAuthError({ code: '404' })
                            );
                            return false;
                        });
                } else {
                    this.db
                        .collection('distributors')
                        .add(c)
                        .catch((e) => {
                            this.store.dispatch(
                                showNotification({
                                    message: 'Error interno ',
                                    alertType: 'error',
                                    component: 'alert',
                                })
                            );
                            this.store.dispatch(
                                throwAuthError({ code: '404' })
                            );
                            return false;
                        });
                }
            });
            return true;
        } catch (e) {
            return false;
        }
    }

    getDistributors(): Observable<DistributorModel[]> {
        this.db.collection('distributors').get().pipe;
        return this.db
            .collection('distributors')
            .get()
            .pipe(
                map((res) =>
                    res.docs.map(
                        (c: any) => new DistributorModel(c.id, c.data())
                    )
                )
            );
    }
    public async updateDistributors(data: any) {
        return this.db
            .collection('distributors')
            .doc(data.id)
            .set({ ...data })
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
    public async deleteDistributor(data: any) {
        return this.db
            .collection('distributors')
            .doc(data.id)
            .delete()
            .then(() => {
                return true;
            })
            .catch((e) => {
                this.store.dispatch(
                    showNotification({
                        message: 'Error interno ',
                        alertType: 'error',
                        component: 'alert',
                    })
                );
                this.store.dispatch(throwAuthError({ code: '404' }));
                return false;
            });
    }
}
