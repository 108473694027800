import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'si-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  Open(url: string ){
    window.open(url,'_blank')
  }

}
