import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keysvalues',
})
export class KeysvaluesPipe implements PipeTransform {
    options: any[];
    transform(value: any[]): unknown {
        let products = [];
        products.push(...value);
        this.getKeys(value);

        let html = '';
        for (let item of products) {
            this._delete(item, 'id');
            html += `<div  class="item">
            
            <div class="product__info">
                <div>
                    <h2>Número de parte:</h2>
                    <p> ${item['catalog_Number']}</p>
                </div>
                <img src="assets/images/640x360.png">
            </div>
            Resultados:
            <div class="product__spects">
            `;

            for (let obj of this.options) {
                if (
                    obj['label'] === 'id' ||
                    obj['label'] === 'selector' ||
                    obj['label'] === 'catalog_Number'
                ) {
                } else {
                    html += `<div class="options">
                    <div class="title">${obj['label']}</div> 
                    <div class="value">${item[obj['label']]}</div>
                    </div>`;
                }
            }
            html += '</div></div>';
        }

        return html;
    }
    _delete(obj, prop) {
        if (obj[prop] && !obj[prop].length) delete obj[prop];
    }
    getKeys(c: any[]) {
        this.options = [];
        if (c.length == 0) {
            return false;
        }
        let object = Object.keys(c[0]);
        for (let key in object) {
            let name = object[key];
            let obj = {};
            obj['label'] = name;
            this.options.push(obj);
        }
        return this.options;
    }
}
