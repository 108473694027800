import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TrainingService } from '../../services/training/training.service';
import { navigate } from '../actions/navigation.actions';
import { showNotification } from '../actions/notifications.actions';
import {
    clearSelectedCategory,
    createTraining,
    deleteTraining,
    // getCategoryCourses,
    getProductCategories,
    // setCategoryCourses,
    setProductCategories,
    updateTraining,
} from '../actions/training.actions';

@Injectable()
export class TrainingEffects {
    constructor(
        private actions$: Actions,
        private trainingServ: TrainingService
    ) {}

    public getProductCategories$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProductCategories),
            switchMap(() => this.trainingServ.getProductCategories()),
            map((productCategories) =>
                setProductCategories({ productCategories })
            )
        );
    });

    public createTraining$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createTraining),
            switchMap(({ selectedCategory }) =>
                this.trainingServ.createProductCategory(selectedCategory)
            ),
            switchMap(() => [
                showNotification({
                    message: 'Se ha enviado tu solicitud exitosamente ',
                    alertType: 'success',
                    component: 'alert',
                }),
                navigate({ url: ['/main/training'] }),
            ])
        );
    });
    public updateTraining$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateTraining),
            switchMap(({ selectedCategory }) =>
                from(this.trainingServ.updateTraining(selectedCategory))
            ),
            switchMap(() => [
                clearSelectedCategory(),
                navigate({ url: ['main', 'training'] }),

                showNotification({
                    message: 'se almaceno la informacion ',
                    alertType: 'success',
                    component: 'alert',
                }),
            ])
        );
    });
    public deleteTraining$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteTraining),
            switchMap(({ selectedCategory }) =>
                from(this.trainingServ.deleteTraining(selectedCategory))
            ),
            switchMap(() => [
                showNotification({
                    message: 'se ha eliminado exitosamente',
                    alertType: 'success',
                    component: 'alert',
                }),
                getProductCategories(),
            ])
        );
    });
}
